<template>
  <div v-if="content">
    <div v-for="section in content" :key="section.id">
      <slot name="section" :section="section">
        <component
          :is="section.contentType"
          v-if="section.contentType"
          :id="section.handle"
          v-bind="section.data"
        />
      </slot>
    </div>
  </div>
</template>
<script>
import BraceletBuilder from '~/components/BraceletBuilder'
import Disclaimer from '@/components/disclaimer/Disclaimer'
import FeaturedCollections from '../../screens/FeaturedCollectionsScreen'
import Accordion from '../../screens/AccordionScreen'
import FeaturedProducts from '../../screens/FeaturedProductsScreen'
import HeroBanner from '../../screens/HeroScreen'
import InputForm from '~/components/InputForm'
import ProductGrid from '~/components/nacelle/ContentProductGrid'
import Row from '@/components/layout/Row'
import StoreLocator from '~/components/integrations/stockist/StoreLocator'
import Testimonial from '~/components/nacelle/ContentTestimonial'
import Testimonials from '~/components/nacelle/ContentTestimonials'
import TextContent from '@/components/disclaimer/Disclaimer'

export default {
  props: {
    content: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Accordion,
    BraceletBuilder,
    Disclaimer,
    FeaturedCollections,
    FeaturedProducts,
    HeroBanner,
    InputForm,
    ProductGrid,
    Row,
    StoreLocator,
    Testimonial,
    Testimonials,
    TextContent
  }
}
</script>
<style lang="scss" scoped></style>
