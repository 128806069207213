<template>
  <div class="c-feature-products-screen">
    <component
      :is="type"
      v-bind="componentProps"
    />
  </div>
</template>

<script>
import nmerge from '~/utils/merge-requests'
import { getCollectionsFromProps } from '~/mixins/getCollectionFromProps'
/**
 * wrap the stateless HomepageFeaturedRings component in a stateful context
 */
export default nmerge({
  components: {
    FeaturedProductsTwoColumns: () => import(
      '@/components/featuredProducts/FeaturedProductsTwoColumns'
    ),
    FeaturedProducts: () => import(
      '@/components/featuredProducts/FeaturedProducts'
    ),
  },
  mixins: [
    getCollectionsFromProps('collections'),
  ],
  props: {
    type: {
      type: String,
      required: true
    },
    collections: {
      type: Array,
      default: () => []
    },
    products: {
      type: Array,
      default: () => []
    },
    collection: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      productsData: [],
    }
  },
  computed: {
    componentProps() {
      return {
        ...this.$props,
        ...this.$attrs,
        products: this.screenedProducts
      }
    },
    screenedProducts() {
      const products = this.products
      const productsData = this.productsData
      const collections = this.collections
      const collectionsData = this.collectionsData
      let data = []
      if (products && productsData && productsData.length) {
        data = productsData
      } else if (collections && collectionsData && collectionsData.length) {
        for (let collection of collectionsData) {
          data.push(...collection.products)
        }
      }
      return data
    }
  },
  async fetch() {
    try {
      this.productsData = await this.$api.getProducts({
        handles: this.products
      })
    } catch (err) {
      console.error(err)
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
