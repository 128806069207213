var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-search-overlay",style:(_vm.overlayStyle)},[_c('div',{staticClass:"c-search-overlay__header",class:[
      'l-flex',
      'l-flex--justify-end',
      'l-flex--align-center'
    ]},[_c('button',{staticClass:"c-search-overlay__close-button t-paragraph-large",class:['l-flex','l-flex--align-center'],on:{"click":function($event){$event.stopPropagation();return _vm.handleClose.apply(null, arguments)}}},[_c('span',[_vm._v(" × ")]),_vm._v("\n      Close\n    ")])]),_vm._v(" "),_c('search-box',{staticClass:"c-search-overlay__search-box",class:{
      'c-search-overlay__search-box--results': !!_vm.query
    },attrs:{"enable-autocomplete":true,"columns":4,"position":"global"}}),_vm._v(" "),(_vm.searchVisible && !_vm.query)?_c('search-screen',{staticClass:"c-search-overlay__search-screen"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }