<!--
/****
/* Individual products are loaded with the getProduct mixin.
/* For instructions related to connecting your invetory to
/* Nacelle, please refer to:
/*
/* https://docs.getnacelle.com/getting-started.html#_2-product-settings
/****
-->
<template>
  <div class="p-product-page">
    <div
      class="nosto_page_type"
      style="display:none"
    >
      product
    </div>
    <section class="u-margin-bottom-xx-large">
      <product-info
        :product="product"
        :products="pdpCollectionData.products"
        :size.sync="selectedRingSize"
        @update:variant="currentProductVariant = $event"
      >
        <template v-slot:default="slotProps">
          <!--
          uses scoped slots found in productInfo.vue's slot to pass the props
          back up to this scope
          @link https://vuejs.org/v2/guide/components-slots.html#Scoped-Slots
        -->
          <product-add-to-cart-button
            v-bind="slotProps"
            custom-label="Send to Myself"
          />
          <!-- Second button is injected with the rise.ai app-->
        </template>
      </product-info>
    </section>
    <a v-utm-adder id="more" />
    <section class="p-product-page__details u-margin-bottom-x-large">
      <div class="l-container">
        <tabs
          :tabs="tabs"
          :get-product-function="fetchProduct"
        />
      </div>
    </section>
    <section
      v-if="forHerProducts.length"
      class="p-product-page__featured-products"
    >
      <featured-products-screen v-bind="giftsForHer" />
    </section>
    <section
      v-if="forHimProducts.length"
      class="p-product-page__featured-products"
    >
      <featured-products-screen v-bind="giftsForHim" />
    </section>
    <shoppable-instagram
      class="l-container u-margin-bottom-x-large"
      :content="shoppableInstagram"
    />
    <section
      v-if="onTheOtherHand && onTheOtherHand.length"
      class="l-container u-margin-bottom-x-large"
    >
      <h2 class="t-heading-5 u-margin-bottom-medium">
        ON THE OTHER HAND.
      </h2>
      <div id="productpage-nosto-1">
        <product-carousel :collections="onTheOtherHand" />
      </div>
    </section>
  </div>
</template>

<script>
import FeaturedProductsScreen from '~/components/screens/FeaturedProductsScreen'
import get from 'lodash/get'
import getProductInCard from '~/mixins/getProductInCard'
import gtmProductEvents from '~/mixins/gtmProductEvents'
import nmerge from '~/utils/merge-requests'
import nosto from '~/mixins/nosto'
import productHandle from '~/mixins/productHandle'
import productMetafields from '~/mixins/productMetafields'
import productPageDetails from '~/mixins/productPageDetails'
import sezzle from '~/mixins/sezzle'
import { getCollectionFromProps } from '~/mixins/getCollectionFromProps'
import { getLineItem } from '@/utils/shopifyHelper'
import { parseId } from '@/utils/parseIds'
import { v4 as uuid } from 'uuid'

export default nmerge({
  mixins: [
    getProductInCard,
    getCollectionFromProps('pdpCollection'),
    sezzle(),
    nosto({
      elements: ['product-page-nosto-1', 'productpage-nosto-2', 'nosto-stl']
    }),
    productMetafields,
    productPageDetails,
    productHandle,
    gtmProductEvents,
  ],
  components: {
    FeaturedProductsScreen
  },
  data() {
    return {
      forHimProducts: [],
      forHerProducts: [],
      giftsForHer: {
        titleMarginBottom: 'x-large',
        title: 'Gifts for Her',
        type: 'FeaturedProducts',
        products: [
          'legends-classic-thin-silicone-ring-dragon',
          'legends-classic-halo-silicone-ring-unicorn',
          'legends-classic-thin-silicone-ring-yeti',
          'legends-classic-thin-silicone-ring-poseidon',
          'legends-classic-halo-silicone-ring-mermaid',
          'legends-classic-thin-silicone-ring-unicorn',
          'legends-classic-thin-silicone-ring-mermaid',
          'elements-classic-thin-silicone-ring-rose-gold',
          'elements-contour-halo-silicone-ring-diamond',
          'braided-stackable-silicone-ring-pink-sand',
          'beaded-stackable-silicone-ring-white',
        ],
      },
      giftsForHim: {
        titleMarginBottom: 'x-large',
        title: 'Gifts for Him',
        type: 'FeaturedProducts',
        products: [
          'elements-classic-silicone-ring-gold',
          'elements-classic-silicone-ring-black-pearl',
          'elements-classic-silicone-ring-platinum',
          'elements-classic-silicone-ring-meteorite',
          'bevel-silicone-ring-slate',
          'elements-contour-silicone-ring-silver',
          'legends-contour-silicone-ring-poseidon',
        ],
      },
    }
  },
  /**
   * @link https://help.rise.ai/en/articles/4858938-using-rise-with-a-headless-store
   */
  async mounted() {
    if (
      process.browser &&
      this.product &&
      this.product.handle &&
      this.product.handle === 'giftcard'
    ) {
      window.Rise = {
        onGiftAdded: (lineItemProps) => {
          if (lineItemProps && lineItemProps.gift) {
            let customAttributes = []
            for (let [ key, value ] of Object.entries(lineItemProps.gift)) {
              if (key && value) {
                customAttributes.push({ key, value })
              }
            }
            return this.$store.dispatch('cart/addLineItem', getLineItem(
              this.product,
              this.currentProductVariant,
              { customAttributes }
            ))
              .then(() => setTimeout(() =>
                this.$store.dispatch('cart/showCart'), 500))
              .catch((err) => console.error('[giftcard]', err))
          }
        },
        is_product_page: true,
        cart: { token: uuid() },
        using_add_to_cart_flow: true,
        is_floating_cart_theme: true,
        product: { id: parseId(this.product.pimSyncSourceProductId) },
        full_product: { available: true }
      }
      if (
        window.Rise &&
        window.Rise.cart &&
        window.Rise.cart.token
      ) {
        this.addMetafields({
          key: 'cart_token',
          value: window.Rise.cart.token
        })
      }
      const i = setInterval(() => {
        if (window.Rise && window.Rise.settings) {
          clearInterval(i)
          // WORKAROUND
          // despite what the docs say, you have to set this property in order
          // to use the onGiftAdded hook.
          window.Rise.settings.headless_without_shopify_cart_flow = true
        }
      }, 500)
      try {
        /**
         * These collections are very generic and contain 150+ items,
         * so trimming them to the first 10 products.
         * Client may want to use a more specific collection going forward?
         */
        const collections = await this.$api.getCollections({
          collections: [
            'pdp-collection-elements-classic-silicone-rings',
            'pdp-collection-legends-classic-silicone-rings'
          ]
        })
        this.forHimProducts = this.shapeCollectionProducts(
          collections[0].products.slice(0, 10)
        )
        this.forHerProducts = this.shapeCollectionProducts(
          collections[1].products.slice(0, 10)
        )
      } catch (err) {
        console.warn('unable to load giftcard collections', err)
      }
    }
  },
  head() {
    const subdomain = get(this.$nuxt, '$options.nacelle.rise.subdomain')
    let script = []
    if (subdomain) {
      script.push({
        hid: `j-str-rise-ai--${subdomain}`,
        src: `https://str.rise-ai.com/?shop=${subdomain}.myshopify.com`,
        defer: true,
      })
    }
    return { script }
  },
  methods: {
    shapeCollectionProducts(products) {
      if (products.length) {
        const shapedProducts = products.map(product => {
          return {
            availableColors: null,
            hoverImage: product.featuredMedia.src,
            hoverImageAlt: product.featuredMedia.altText,
            image: product.featuredMedia.src,
            imageAlt: product.featuredMedia.altText,
            originalPrice: product.variants[0].compareAtPrice,
            price: `$${product.priceRange.min}`,
            title: product.title,
            url: `/products/${product.handle}`
          }
        })
        return shapedProducts
      } else {
        return []
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.p-product-page {
  padding: $section-padding;
  &__details {
    @include themify($themes) {
      background-color: themed('background', 'xx-light');
    }
  }
}
</style>
