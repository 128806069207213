<template>
  <div class="c-refinement-filters">
    <header class="c-refinement-filters__heading">
      <h2 class="c-refinement-filters__title t-heading-5 l-flex l-flex--justify-start">
        <span> Filters </span>
      </h2>
      <interface-close-button
        class="c-refinement-filters__close-button"
        @close="$emit('close')"
      />
    </header>
    <div
      v-if="filters"
      class="c-refinement-filters__filters"
      :class="[
        'l-flex'
      ]"
    >
      <active-filters
        :filters="filters"
        :active-filters="activeFilters"
        @input="toggleFilterActive"
      />
      <template
        v-for="filter in filters"
      >
        <refinement-filter-screen
          :key="filter.property.label"
          v-bind="{ ...filter, activeFilters }"
          class="c-refinement-filters__section"
          @input="toggleFilterActive"
        />
      </template>
      <accordion
        class="c-refinement-filters__section"
        title="Price"
      >
        <price-filter
          :price-range-filters="priceRangeFilters"
          :active-price-range="activePriceRange"
          @input="togglePriceRangeActive"
        />
      </accordion>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/accordion/Accordion'
import ActiveFilters from '@/components/filter/ActiveFilters'
import InterfaceCloseButton from '~/components/nacelle/InterfaceCloseButton'
import PriceFilter from '@/components/filter/PriceFilter'
import queryString from 'query-string'
import RefinementFilterScreen from '~/components/screens/RefinementFilterScreen'
import uniq from 'lodash/uniq'
import { mapState, mapMutations } from 'vuex'
import { omit } from 'search-params'

export default {
  components: {
    Accordion,
    ActiveFilters,
    InterfaceCloseButton,
    PriceFilter,
    RefinementFilterScreen
  },
  props: {
    inputData: {
      type: Array,
      required: true
    },
    propertyFilters: {
      type: Array,
      required: true
    },
    priceRangeFilters: {
      type: Array,
      default: () => []
    },
    passingConditions: {
      type: Array,
      required: false,
      default: () => []
    },
    quickFilter: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      filters: [],
      filteredData: null,
      activeFilters: [],
      outputData: null,
      activePriceRange: null,
      passedData: null,
      sortBy: 'Sort By'
    }
  },
  computed: {
    ...mapState('search', ['filtersCleared'])
  },
  watch: {
    inputData() {
      try {
        // console.log(this.inputData)
        this.setupFilters()
        this.computeFilteredData()
      } catch (err) {
        console.warn(err)
      }
    },
    outputData() {
      this.$emit('updated', this.outputData)
      this.checkCleared()
      this.updateFilterCount()
    },
    filters() {
      try {
        this.computeFilteredData()
      } catch (err) {
        console.error(err)
      }
    },
    activeFilters() {
      try {
        this.computeFilteredData()
        this.checkCleared()
      } catch (err) {
        console.error(err)
      }
    },
    activePriceRange(priceRange) {
      try {
        this.computeOutputData()
        if (
          priceRange &&
          priceRange.range &&
          priceRange.range.length &&
          priceRange.range.map
        ) {
          this.setFilterInQueryParams({
            property: 'priceRange',
            value: priceRange.range
          })
        }
        this.checkCleared()
      } catch (err) {
        console.error(err)
      }
    },
    sortBy() {
      this.computeOutputData()
    },
    filtersCleared(val) {
      if (val === true) {
        this.activeFilters = []
        this.activePriceRange = null
        this.sortBy = 'Sort By'
        this.removeFiltersInQueryParams()
        this.checkCleared()
      }
    },
    quickFilter(filter) {
      this.toggleFilterActive(filter)
    }
  },
  async created() {
    if (process.browser) {
      try {
        await this.$nextTick()
        this.passedData = this.getPassedData()
        this.setupFilters()
        this.activeFilters = this.readFiltersFromQueryParams({
          omit: [ 'priceRange' ]
        })
        this.activePriceRange = this.readPriceRangeFromQueryParams()
        if (this.filteredData && this.outputData.length > 0) {
          this.$emit('updated', this.outputData)
        }
      } catch (err) {
        console.warn(err)
      }
    }
  },
  methods: {
    ...mapMutations('search', [
      'setFiltersCleared',
      'setFiltersNotCleared',
    ]),
    updateFilterCount() {
      const activeFilters = this.activeFilters
      let result = 0
      for (let filter of activeFilters || []) {
        if (filter && filter.values && filter.values.length) {
          result += filter.values.length
        }
      }
      this.$emit('update:filter-count', result)
    },
    checkCleared() {
      const activeFilters = this.activeFilters
      const activePriceRange = this.activePriceRange
      console.log('checkCleared', { activeFilters, activePriceRange })
      if (
        activeFilters &&
        activeFilters.length === 0 &&
        activePriceRange === null
      ) {
        this.$emit('clear')
      }
    },
    async clearFilters() {
      this.setFiltersCleared()
    },
    computeOutputData() {
      const vm = this
      const outputWorker = new Worker('/outputWorker.js')
      outputWorker.postMessage({
        activeFilters: this.activeFilters,
        filteredData: this.filteredData,
        activePriceRange: this.activePriceRange,
        sortBy: this.sortBy
      })
      outputWorker.onmessage = function (e) {
        vm.outputData = e.data
      }
    },
    computeFilteredData() {
      const vm = this
      const filterWorker = new Worker('/filterWorker.js')
      filterWorker.postMessage({
        activeFilters: this.activeFilters,
        inputData: this.inputData
      })
      filterWorker.onmessage = function (e) {
        vm.filteredData = e.data
        vm.computeOutputData()
      }
    },
    setupFilters() {
      const vm = this
      const propertyFilters = this.propertyFilters
      const inputData = this.inputData
      if (inputData && propertyFilters) {
        let propertyFilterMap = []
        let filterMap = {}
        for (let filter of propertyFilters) {
          propertyFilterMap[filter.field] = filter.label
        }
        for (let item of inputData) {
          if (!item.metafieldsMap && (process.env.NODE_ENV !== 'production')) {
            // if we don't have a metafields map
            console.warn(
              'item.metafieldsMap is missing!',
              'make sure you are mapping the products correctly',
              `(import { mapProduct(s) } from '~/utils/shopifyHelper')`,
              'before you pass them to this component.',
              'this is not the right place to fix this problem ;)'
            )
          }
          // filter by metafield key/value
          for (let {key, value} of Object.values(item.metafieldsMap || {})) {
            // console.log('setupFilters/metafields', { key, value })
            if (propertyFilterMap[key]) {
              if (!filterMap[key]) {
                if (propertyFilterMap[key] == "Color") {
                  filterMap.tone = {
                    property: {
                      field: key,
                      label: 'tone'
                    },
                    type: 'tone',
                    values: []
                  }
                  filterMap.finish = {
                    property: {
                      field: key,
                      label: 'finish'
                    },
                    type: 'finish',
                    values: []
                  }
                }
                filterMap[key] = {
                  property: {
                    field: key,
                    label: propertyFilterMap[key]
                  },
                  values: []
                }
              }
              // omit certain fields
              if (['engraving'].includes(key)) {
                delete filterMap[key]
              } else {
                if (filterMap.tone) {
                  filterMap.tone.values = uniq([
                    ...filterMap.color.values,
                    value
                  ])
                }
                if (filterMap.finish) {
                  filterMap.finish.values = uniq([
                    ...filterMap.color.values,
                    value
                  ])
                }
                filterMap[key].values = uniq([
                  ...filterMap[key].values,
                  value
                ])
              }
            }
          }
          if (!item.facets && process.env.FEAT_DETECT_MISSING_FACETS) {
            console.warn('item.facets is missing!', item)
          }
          // filter by facets, if available
          for (let {name, value} of (item.facets || [])) {
            if (
              (name !== 'Title') &&
              propertyFilterMap[name]
            ) {
              if (!filterMap[name]) {
                filterMap[name] = {
                  property: {
                    field: name,
                    label: propertyFilterMap[name]
                  },
                  values: []
                }
              }
              filterMap[name].values = uniq([
                ...filterMap[name].values,
                value
              ])
            }
          }
        }
        if (vm.filters.length == 0) {
          propertyFilters.forEach(property => {
            if (filterMap.hasOwnProperty(property.field)) {
              vm.filters.push(filterMap[property.field])
            }
          })
        }
        vm.$emit('update:filters', vm.filters)
      }
    },
    filterActive(value) {
      return requestAnimationFrame(() => {
        if (this.activeFilters) {
          const filterArray = this.activeFilters.filter(filter => {
            return filter.value === value
          })
          if (filterArray.length > 0) {
            return true
          } else {
            return false
          }
        }
      })
    },
    toggleFilterActive(filter) {
      const filterInFilters = this.activeFilters.filter(filtersItem => {
        return filtersItem.property === filter.property
      })
      if (filterInFilters.length === 0) {
        this.activeFilters.push({
          property: filter.property,
          values: [filter.value]
        })
      } else {
        // mutate activeFilters
        for (let [index, filtersItem] of Object.entries(this.activeFilters)) {
          const isMatch = filtersItem.property === filter.property
          const isActiveFilter = filtersItem.values.some(value => value === filter.value)
          // if a swap property is defined on the filter update object, remove
          // the passed value from the active filter.
          if (
            isMatch &&
            filter.swap !== undefined &&
            filtersItem.values.some(value => value === filter.swap)
          ) {
            const swapIndex = filtersItem.values.indexOf(filter.swap)
            filtersItem.values.splice(swapIndex, 1)
            console.log('af', filter)
          }
          // process filter change
          if (isMatch && !isActiveFilter) {
            filtersItem.values.push(filter.value)
          } else if (isMatch && isActiveFilter) {
            const filterIndex = filtersItem.values.indexOf(filter.value)
            filtersItem.values.splice(filterIndex, 1)
          }
          // remove the filter if empty
          if (filtersItem.values.length === 0) {
            this.activeFilters.splice(index, 1)
          }
        }
      }
      this.setFilterInQueryParams(filter)
      this.setFiltersNotCleared()
      this.computeFilteredData()
      this.$emit('active-filters', this.activeFilters)
    },
    async togglePriceRangeActive(priceRange) {
      if (
        JSON.stringify(this.activePriceRange) === JSON.stringify(priceRange)
      ) {
        this.activePriceRange = null
      } else {
        this.activePriceRange = priceRange
      }
    },
    setFilterInQueryParams() {
      return this.$nextTick(() => {
        if (process.browser) {
          let transformedParams = {}
          for (let param of this.activeFilters) {
            if (param.values && param.values.length > 0) {
              transformedParams[param.property] = param.values.join(',')
            } else {
              transformedParams[param.property] = param.value
            }
          }
          this.$router.push({
            query: { ...transformedParams }
          })
        }
      })
    },
    removeFiltersInQueryParams() {
      if (process.browser) {
        const propertyFilters = [
          ...(this.propertyFilters || []),
          { field: 'priceRange' }
        ]
        const filtersFromUrl = propertyFilters.map(filter => {
          return filter.field
        })
        const queryParamsString = queryString.stringify(
          queryString.parse(location.search)
        )
        const queryWithoutFilters = omit(queryParamsString, filtersFromUrl)
        const query = queryString.parse(queryWithoutFilters.querystring)
        this.$router.push({ query })
      }
    },
    readPriceRangeFromQueryParams() {
      let parsed = Object.entries(
        queryString.parse(location.search, { arrayFormat: 'comma' })
      )
      let result = null
      for (let [ key, value ] of (parsed || [])) {
        if ((key === 'priceRange') && value && value.length) {
          const [ min, max ] = value
          result = {
            label: `$${min} - $${max}`,
            range: value
          }
          break
        }
      }
      return result
    },
    readFiltersFromQueryParams(options) {
      const { omit } = options || { omit: [] }
      let result = []
      let parsed = Object.entries(
        queryString.parse(location.search, { arrayFormat: 'comma' })
      )
      parsed = Object.fromEntries(
        parsed.map(filter => {
          if (typeof filter[1] === 'string') {
            filter[1] = [filter[1]]
          }
          return filter
        })
      )
      const propertyFilters = [
        ...(this.propertyFilters || []),
        { field: 'priceRange' }
      ]
      let filtersFromUrl = []
      for (let { field } of propertyFilters) {
        let filter = {
          property: field,
          values: parsed[field]
        }
        if (field === 'priceRange' && parsed[field]) {
          filter.values = [
            parsed[field]
          ]
        }
        if (
          filter.values !== null &&
          filter.values !== undefined &&
          filter.values.length > 0 &&
          !omit.includes(filter.property)
        ) {
          filtersFromUrl.push(filter)
        }
      }
      if (filtersFromUrl.length > 0) {
        result = filtersFromUrl
      }
      return result
    },
    getPassedData() {
      const vm = this
      if (vm.passingConditions) {
        return vm.inputData.filter(item => {
          const conditions = vm.passingConditions.map(passingCondition => {
            const passing = new Function(
              `return "${passingCondition.value}" ${
                passingCondition.conditional
              } "${item[passingCondition.property]}"`
            )

            return passing()
          })
          const passedConditions = conditions.every(condition => {
            return condition === true
          })
          return passedConditions === true
        })
      } else {
        return vm.inputData
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-refinement-filters {
  $heading-height: rem(75px);
  height: 100%;
  &__heading {
    position: relative;
    width: 100%;
    border-bottom: 1px solid;
    padding: $filter-section-padding;
  }
  &__title {
    position: relative;
  }
  &__clear-button,
  &__close-button {
    height: 24px;
    position: absolute;
  }
  &__clear-button {
    font-size: small;
    text-transform: uppercase;
    padding: 0;
    background: none;
    border: none;
    text-decoration: underline;
    margin-left: rem(10px);
    cursor: pointer;
    top: rem((35px / 2) - (24px / 2));
  }
  &__close-button {
    right: rem(26px);
    top: rem((75px / 2) - (24px / 2));
  }
  &__filters {
    height: calc(100% - #{$heading-height});
    overflow: auto;
  }

  &__section {
    width: 100%;
    @include themify($themes) {
      border-top: $border themed('foreground', 'filter-section-border');
    }
  }
}
</style>
