<template>
  <btn
    class="c-checkout-button"
    :class="{
      'c-checkout-button--loading': loading,
    }"
    :button-size="['large', 'thin']"
    :is-disabled="loading"
    :type="buttonType"
    @click="checkout"
  >
    <span class="c-checkout-button__text"> {{ checkoutText }} now</span>
  </btn>
</template>

<script>
import Btn from '@/components/button/Btn'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  components: {
    Btn,
  },
  props: {
    checkoutText: {
      type: String,
      default: 'Checkout'
    },
    buttonType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: mapGetters('cart', ['cartSubtotal']),
  methods: {
    ...mapMutations('cart', ['setCartError']),
    ...mapActions('checkout', ['processCheckout']),
    async checkout() {
      this.loading = true
      try {
        await this.processCheckout({
          async beforeCreate() {
            // Allows processing of cart before checkout creation.
          },
          async beforeRedirect() {
            // Allows processing after checkout create and before redirecting.
          }
        })
      } catch (err) {
        console.error('checkout', err)
        this.setCartError(err)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-checkout-button {
  &--loading {
    cursor: progress;
  }
}
</style>
