<template>
  <button
    title="Remove line item."
    class="c-cart-item-remove-button"
    @click="removeLineItem(lineItem)"
  >
    <slot>
      <img
        aria-hidden="true"
        class="c-cart-item-remove-button__icon"
        src="~/assets/icons/default-close-icon.svg"
        alt="Remove"
      >
    </slot>
  </button>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    lineItem: {
      type: Object,
      required: true
    }
  },
  methods: mapActions('cart', ['removeLineItem'])
}
</script>

<style lang="scss" scoped>
.c-cart-item-remove-button {
  border: none;
  background: none;
  cursor: pointer;
  opacity: 0.5;
  &__icon {
    width: rem(13px);
    height: rem(13px);
  }
}
</style>
