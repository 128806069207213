<template>
  <span class="c-product-price t-heading-8">
    <span
      class="c-product-price__actual"
    ><!--
      -->{{ priceValue | currency }}<!--
    --></span>
    <template v-if="showBogo">
      <span class="c-product-price__bogo"><!--
        -->BOGO<!--
      --></span>
      <ProductPricePill
        v-if="amountOff"
        color="cyan"
      ><!--
      -->{{ amountOff | currency }} off!<!--
      --></ProductPricePill>
    </template>
    <template v-else-if="showDiscount">
      <span
        class="c-product-price__compare-at u-strikeout"
      ><!--
        -->{{ compareAtPrice | currency }}<!--
      --></span>
      <ProductPricePill
        v-if="amountOff"
        color="cyan"
      ><!--
      -->{{ amountOff | currency }} off!<!--
      --></ProductPricePill>
      <ProductPricePill
        v-else
        color="cyan"
      ><!--
        -->{{ discount | percent }} off!<!--
      --></ProductPricePill>
    </template>
    <ProductPricePill color="red" v-if="isClearanceItem">Final Sale</ProductPricePill>
  </span>
</template>

<script>
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import { mapGetters } from 'vuex'
import ProductPricePill from "@/components/productPricePill/ProductPricePill";
export default {
  components:{
    ProductPricePill
  },
  filters: {
    percent(value) {
      if (value) {
        return `${Math.round(value * 100)}%`
      }
    }
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    price: {
      type: [String, Number],
      default: 0.00
    }
  },
  computed: {
    isClearanceItem() {
      const product = this.product
      const tags = get(product, 'tags') || []
      return tags.includes('clearance')
    },
    ...mapGetters('cart', ['productHasGiftWithPurchase']),
    showBogo() {
      const product = this.product
      let result = false
      if (product && product.discountApplied) {
        const { tier } = product.discountApplied
        result = (tier.discountType === 'bogo')
      }
      return result
    },
    priceValue() {
      const giftWithPurchase = this.giftWithPurchase
      const price = this.toNumber(this.price)
      return (giftWithPurchase) ? 0.00 : price
    },
    compareAtPrice() {
      const product = this.product
      const price = this.price
      const compareAtPrice = (
        get(product, 'variant.compareAtPrice') ||
        get(product, 'compareAtPrice')
      )
      const giftWithPurchase = this.giftWithPurchase
      return (giftWithPurchase)
        ? this.toNumber(price)
        : this.toNumber(compareAtPrice)
    },
    amountOff() {
      const product = this.product
      let result = null
      if (product && product.discountApplied) {
        const { tier } = product.discountApplied
        if (tier.discountType === 'dollar-amount') {
          // console.log(tier, product)
          result = tier.discountAmount * product.quantity
        } else if (tier.discountType === 'bogo') {
          result = tier.amountOff
        }
      }
      return result
    },
    discount() {
      const price = this.priceValue
      const compareAtPrice = this.compareAtPrice
      let result = null
      if (isNumber(price) && isNumber(compareAtPrice)) {
        if (price === 0.00) {
          result = 1.00
        } else {
          const diff = compareAtPrice - price
          result = (diff / compareAtPrice)
        }
      }
      return result
    },
    giftWithPurchase() {
      return this.productHasGiftWithPurchase(this.product)
    },
    showDiscount() {
      const discount = this.discount
      const compareAtPrice = this.compareAtPrice
      return isNumber(discount) && isNumber(compareAtPrice)
    }
  },
  methods: {
    toNumber(price) {
      let result = null
      if (isString(price)) {
        result = parseFloat(price, 10)
      } else if (isNumber(price)) {
        result = price
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.c-product-price {
  &__amount-off {
    background-color: $cyan;
    color: #fff;
    border-radius: rem(12px);
    padding: 0 rem(7px);
  }
}
</style>
