<template>
  <div v-if="filterComponent && usefulFilter">
    <accordion
      :title="filterLabel"
    >
      <component
        :is="filterComponent"
        v-bind="{ property, values, activeFilter }"
        @input="$emit('input', $event)"
      />
    </accordion>
  </div>
</template>

<script>
import Accordion from '@/components/accordion/Accordion'
import ColorFilter from '@/components/filter/ColorFilter'
import WidthFilter from '@/components/filter/FormFactorFilter'
import GenderFilter from '@/components/filter/GenderFilter'
import EngravingFilter from '@/components/filter/EngravingFilter'
import RefinementFilterScreenMixin from '@/mixins/refinementFilterScreen'
import SizeFilter from '@/components/filter/SizeFilter'

export default {
  components: {
    EngravingFilter,
    Accordion,
    ColorFilter,
    WidthFilter,
    GenderFilter,
    SizeFilter
  },
  mixins: [
    RefinementFilterScreenMixin
  ],
  props: {
    property: {
      type: Object,
      required: true
    },
    values: {
      type: Array,
      required: true
    },
    activeFilters: {
      type: Array,
      default: () => [String]
    }
  },
  computed: {
    usefulFilter() {
      const values = this.values
      return !!(values && (values.length > 1))
    }
  }
}
</script>

<style lang="scss" scoped>
.c-filter-screen {
  width: 100%;
}
</style>
