var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-quantity-updater",class:[
    'l-flex'
  ],attrs:{"aria-describedby":_vm.ariaInputId}},[_c('button',{class:[
      'c-quantity-updater__switch',
      'c-quantity-updater__switch--decrement'
    ],attrs:{"aria-describedby":_vm.ariaInputId,"aria-label":"quantity decrease"},on:{"mousedown":function($event){return _vm.start(_vm.decrement)},"touchstart":function($event){$event.preventDefault();return _vm.start(_vm.decrement)},"touchend":function($event){$event.preventDefault();return _vm.stop.apply(null, arguments)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("◀")])]),_vm._v(" "),_c('input',{staticClass:"c-quantity-updater__input",attrs:{"id":_vm.ariaInputId,"aria-label":"line item quantity selector.","type":"text"},domProps:{"value":_vm.quantity},on:{"input":function($event){_vm.update(Number($event.target.value))}}}),_vm._v(" "),_c('button',{class:[
      'c-quantity-updater__switch',
      'c-quantity-updater__switch--increment'
    ],attrs:{"aria-label":"quantity increase"},on:{"mousedown":function($event){return _vm.start(_vm.increment)},"touchstart":function($event){$event.preventDefault();return _vm.start(_vm.increment)},"touchend":function($event){$event.preventDefault();return _vm.stop.apply(null, arguments)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("▶")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }