<template>
  <div
    class="c-search-panel"
    :class="isMobile ? '' : [
      'l-grid',
      'l-grid--align-start',
      'l-grid--2-col-second-long',
    ]"
  >
    <div class="c-search-panel__col c-search-panel__col--left">
      <template v-if="popularSearches">
        <h2 
          :class="headingStyle" 
          class="u-margin-bottom-x-small"
        >
          Popular Searches
        </h2>
        <hr>
        <ul class="c-search-panel__link-list t-list">
          <li
            v-for="(link, index) in displayedPopularSearches"
            :key="'j-popular-search--' + index"
            class="t-paragraph-small"
          >
            <nuxt-link
              :to="link.linkUrl"
              class="c-search-panel__link t-link"
            >
              {{ link.title }}
            </nuxt-link>
          </li>
        </ul>
      </template>
      <template v-if="recentSearches.length > 0">
        <h2 class="t-paragraph-large u-margin-bottom-x-small">
          Your Recent Searches
          <span
            title="Clear Recent Searches"
            class="c-search-panel__clear-btn t-paragraph-small"
            @click="clearSearches()"
          >
            Clear
          </span> 
        </h2>
        <hr>
        <ul class="c-search-panel__link-list t-list">
          <li
            v-for="(query, index) in recentSearches"
            :key="'j-recent-search--' + index"
            class="t-paragraph-small"
          >
            <nuxt-link
              :to="'/search?q=' + query"
              class="c-search-panel__link t-link"
            >
              <span class="c-search-panel__link-icon">
                {{ query }}
              </span>
            </nuxt-link>
          </li>
        </ul>
      </template>
    </div>
    <div class="c-search-panel__col u-text-align-left">
      <h2 class="t-paragraph-large u-margin-bottom-x-small">
        Trending Now
      </h2>
      <product-grid
        class="c-search-panel__product-grid"
        :products="trendingProducts.slice(0, 4)"
        :enable-stars="true"
        :compact="true"
      />
    </div>
  </div>
</template>

<script>
import { RecentSearchesMixin } from '~/mixins/SearchMixin'
import ProductGrid from '@/components/productGrid/ProductGrid'
import isMobile from '@mixins/isMobile';
export default {
  components: {
    ProductGrid,
  },
  mixins: [
    RecentSearchesMixin,
    isMobile
  ],
  props:{
    popularSearches: {
      type: Array,
      default: null
    },
    trendingProducts: {
      type: Array,
      default: null
    }
  },
  computed: {
    displayedPopularSearches() {
      const popularSearches = this.popularSearches
      const isMobile = this.isMobile
      let result = popularSearches
      if (isMobile) {
        result = popularSearches.slice(0,3)
      }
      return result
    },
    headingStyle() {
      const isMobile = this.isMobile
      let result = 't-paragraph-large'
      if (isMobile) {
        result = 't-paragraph'
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.c-search-panel {
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
  @include respond(phone) {
    max-width: 100vw;
  }
  &__col--left {
    text-align: start;

  }
  &__link-list {
    list-style: none;
    margin-left: 0;
  }
  &__link-icon {
    padding-left: rem(20px);
    width: rem(15px);
    height: rem(15px);
    background-image: url('~@/assets/icons/search.svg');
    background-repeat: no-repeat;
    background-size: rem(16px);
  }
  &__link {
    display: block;
    font-size: 14px;
    &:hover{
      text-decoration: none;
    }
  }
  &__product-grid {
    padding-top: 0;
  }
  &__clear-btn {
    text-decoration: underline;
    cursor: pointer;
  }
  hr {
    margin: rem(10px) 0;
    @include respond(phone) {
      display: block;
    }
    @include respond(tab-port) {
      display: block;
    }
    @include respond(tab-land) {
      display: block;
    }
    display: none;
  }
}
.l-grid--2-col-second-long {
  @include respond(tab-land) {
      grid-template-columns: .5fr 2fr;
    }
}
</style>
