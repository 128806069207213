<template>
  <div class="page-content nacelle">
    <slot :page="page">
      <div
        v-for="section in mappedSections"
        :key="section.id"
      >
        <slot
          name="section"
          :section="section"
        >
          <component
            :is="section.contentType"
            v-if="section.contentType"
            :id="section.handle"
            v-bind="section.data"
            :get-product-function="fetchProduct"
          />
        </slot>
      </div>
    </slot>
    <slot
      name="body"
      :body="body"
    >
      <div class="page-content-body section">
        <div class="container">
          <div class="columns is-centered">
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="column is-8 content"
              v-html="body"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import Accordion from '~/components/screens/AccordionScreen'
import ContentCarousel from '@/components/carousel/ContentCarousel'
import Disclaimer from '@/components/disclaimer/Disclaimer'
import FeaturedCollections from '~/components/screens/FeaturedCollectionsScreen'
import FeaturedProducts from '~/components/screens/FeaturedProductsScreen'
import HeroBanner from '~/components/screens/HeroScreen'
import InputForm from '~/components/InputForm'
import PageContentMixin from '~/mixins/PageContentMixin'
import ProductGrid from '~/components/nacelle/ContentProductGrid'
import Row from '@/components/layout/Row'
import Tabs from '@/components/tabs/Tabs'
import FeaturedCollection from '@/components/carousel/FeaturedCollection'
import Testimonial from '~/components/nacelle/ContentTestimonial'
import Testimonials from '~/components/nacelle/ContentTestimonials'
import TextContent from '@/components/disclaimer/Disclaimer'
import getProductInCard from "../../mixins/getProductInCard";
import FeaturedRow from "@/components/layout/FeaturedRow"
import FeaturedRingSize from "@/components/featuredProducts/FeaturedRingSize"
import NostoElement from "@/components/nosto/NostoElement"

export default {
  components: {
    Accordion,
    BraceletBuilder: () => import('~/components/BraceletBuilder'),
    ContentCarousel,
    Disclaimer,
    FeaturedCollections,
    FeaturedProducts,
    HeroBanner,
    InputForm,
    ProductGrid,
    Row,
    StoreLocator: () => import('~/components/integrations/stockist/StoreLocator'),
    SocialMediaGallery: () => import('~/components/screens/SocialMediaGallery'),
    Testimonial,
    Testimonials,
    TextContent,
    Tabs,
    FeaturedRow,
    FeaturedCollection,
    FeaturedRingSize,
    NostoElement
  },
  mixins: [
    PageContentMixin,
    getProductInCard
  ]
}
</script>

<style></style>
