import { render, staticRenderFns } from "./AccordionScreen.vue?vue&type=template&id=2ed73e00&scoped=true&"
import script from "./AccordionScreen.vue?vue&type=script&lang=js&"
export * from "./AccordionScreen.vue?vue&type=script&lang=js&"
import style0 from "./AccordionScreen.vue?vue&type=style&index=0&id=2ed73e00&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ed73e00",
  null
  
)

export default component.exports