<template>
  <shoppable-instagram
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
  />
</template>

<script>
import ShoppableInstagram from '@/components/instagram/ShoppableInstagram'
export default {
  components: {
    ShoppableInstagram,
  }
}
</script>
