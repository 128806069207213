<script>
import Cookies from 'js-cookie'
import get from 'lodash/get'
import localforage from 'localforage'
import { mapState, mapGetters, mapActions } from 'vuex'
if (process.client) {
  window.EnsoCookies = Cookies
}
export default {
  computed: {
    ...mapGetters('space', ['getMetatag']),
    ...mapGetters('engraving', ['engravingVisible']),
    ...mapState('cart', ['cartVisible']),
    ...mapState('modal', ['dynamicBundleVisible']),
    /**
     * if we need to prevent scrolling in the application, just add the property
     * here using an `||`.
     */
    scrollLockEnabled() {
      const cartVisible = this.cartVisible
      const engravingVisible = this.engravingVisible
      const dynamicBundleVisible = this.dynamicBundleVisible
      return (
        cartVisible ||
        engravingVisible ||
        dynamicBundleVisible
      )
    },
    drtvEnabled() {
      return !!get(this.$config, 'drtv.enabled')
    }
  },
  watch: {
    scrollLockEnabled(enabled) {
      document.body.style['overflow-y'] = (enabled) ? 'hidden' : null
    },
  },
  async mounted() {
    const smileFixInterval = setInterval(() => {
      let smileItem = document.getElementById('smile-ui-container')
      if (smileItem && smileItem.style) {
        clearInterval(smileFixInterval)
        smileItem.style.zIndex = 1
      }
    }, 5000)

    await this.initializeCheckout()
    await this.initializeCart()

    if (process.env.NODE_ENV !== 'production') {
      console.log(`NODE_ENV=${process.env.NODE_ENV}`)
      localforage.clear()
    }
    this.readSession()

    // Open cart when we click cart icon on shopify account page
    const { query } = this.$route
    if (query && query.openCart === 'true') {
      this.showCart();
    }
  },
  methods: {
    ...mapActions('cart', ['initializeCart', 'showCart']),
    ...mapActions('checkout', ['initializeCheckout']),
    ...mapActions('user', ['readSession'])
  },
  head() {
    let properties = {}
    let meta = []
    let link = []
    let script = []
    const title = this.getMetatag('title')
    const description = this.getMetatag('description')
    const image = this.getMetatag('og:image')
    const { path } = this.$route
    const pathWithoutSlash = path.endsWith('/') ? path.slice(0, -1) : path

    // we can also push alternates here (rss, i18n, &c.)
    link.push({
      rel: 'canonical',
      href: `https://${process.env.SITE_DOMAIN}${pathWithoutSlash}`
    })

    if (process.env.YOTTAA_ENABLED) {
      script.push({
        hid: 'yottaa-in-site',
        src: `https://cdn.yottaa.com/rapid.min.js?key=${process.env.YOTTAA_KEY}`
      })
    }
    if (process.env.KUSTOMER_ENABLED) {
      script.push({
        hid: 'kustomer-chat',
        src: `https://cdn.kustomerapp.com/chat-web/widget.js`,
        'data-kustomer-api-key': process.env.KUSTOMER_API_KEY,
        body: true,
        callback: () => {
          console.log('KUSTOMER init')
          try {
            window.Kustomer.start({ brandId: `${process.env.KUSTOMER_BRAND_ID}` })
          } catch (err) {
            console.error(err)
          }
        }
      })
    }

    if (title) {
      properties.title = title.value
      meta.push({
        hid: 'og:title',
        property: 'og:title',
        content: title.value
      })
      meta.push({
        hid: 'og:site_name',
        property: 'og:site_name',
        content: title.value
      })
    }

    if (description) {
      meta.push({
        hid: 'description',
        name: 'description',
        content: description.value
      })
      meta.push({
        hid: 'og:description',
        property: 'og:description',
        content: description.value
      })
    }

    if (image) {
      meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: image.value
      })
    }

    meta.push({
      hid: 'og:type',
      property: 'og:type',
      content: 'website'
    })

    return {
      ...properties,
      meta,
      link,
      script,
    }
  }
}
</script>

<style lang="scss" scoped>
.u-app-wrapper {
  height: 100vh;
  height: -webkit-fill-available;
  /* overflow-y: scroll; */
}
.u-scroll-lock {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.c-app {
  height: 100%;
}
</style>

<style lang="scss">
#kustomer-ui-sdk-iframe {
  right: unset;
  left: 20px;
}
</style>
