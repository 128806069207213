<template>
  <div
    class="c-variant-title t-heading-13 t-heading-13--x-light"
    :class="[
      'l-flex',
      'l-flex--column',
      'l-flex--align-start',
      't-heading-13',
      't-heading-13--x-light'
    ]"
  >
    <!-- display line item properties -->
    <div
      v-for="variantProp in variantProps"
      :key="variantProp.label"
      class="c-variant-title__prop"
    >
      <template v-if="variantProp.label && variantProp.value">
        <span class="c-variant-title__prop-label">{{
          variantProp.label | capitalize
        }}:</span>
        <span
          class="c-variant-title__prop-value"
          :style="variantProp.style"
        >{{
          variantProp.value
        }}</span>

        <product-price
          v-if="sleeveFee && (variantProp.label === 'sleeve') && !variantProp.value.includes('EMPR') && !variantProp.value.includes('REBEL')"
          :size="'small'"
          :price="sleeveFee.price | currency"
          :compare-at-price="sleeveFee.compareAtPrice | currency"
        />
      </template>
      <span
        v-else
        class="c-variant-title__prop-label"
      >
        {{ variantProp.label }}
      </span>
    </div>
    <!-- edit engraving -->
    <div
      v-if="canEditEngraving"
      class="c-variant-title__edit-engraving"
    >
      <product-price
        v-if="engravingFee && item.customAttributes && item.customAttributes.find((obj)=>obj.key=='_engravingDiscount')"
        :price="engravingFee.price - engravingDiscount | currency"
        :compare-at-price="engravingFee.compareAtPrice | currency"
        :size="'small'"
      />
      <product-price
        v-else-if="engravingFee"
        :price="engravingFee.price | currency"
        :compare-at-price="engravingFee.compareAtPrice | currency"
        :size="'small'"
      />
      <button
        title="Edit engraving."
        class="c-variant-title__button"
        @click="editEngraving"
      >
        <img
          class="c-variant-title__crud-icon"
          src="~assets/icons/pencil.svg"
          alt="edit"
        >
      </button>
      <button
        title="Remove engraving."
        class="c-variant-title__button"
        @click="deleteEngraving(item)"
      >
        <img
          class="c-variant-title__crud-icon"
          src="~assets/icons/default-close-icon.svg"
          alt="remove"
        >
      </button>
    </div>
    <!-- engraving cta -->
    <div
      v-else-if="wantsEngraving"
      class="c-variant-title__engraving-cta"
    >
      <button
        aria-label="Add or edit engraving."
        class="c-variant-title__engraving-cta-button t-heading-13"
        @click="editEngraving"
      >
        <img
          class="c-variant-title__cta-icon"
          src="~assets/icons/pencil.svg"
          alt="edit"
        >
        <span>
          Add Engraving
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'
import intersection from 'lodash/intersection'
import capitalize from 'lodash/capitalize'
import { mapState, mapActions, mapMutations } from 'vuex'
import ProductPrice from '@/components/productPrice/ProductPrice'
export default {
  filters: {
    capitalize: value => (value ? capitalize(value) : '')
  },
  components: {
    ProductPrice
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => ({})
    },
    engravingFee: {
      type: Object,
      default: () => ({})
    },
    sleeveFee: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState('engraving', ['engravingKeys']),
    engravingFont() {
      let result
      const item = this.item
      if (item.customAttributes) {
        for (let { key, value } of item.customAttributes) {
          if (key && (key === 'font')) {
            result = value
            break
          }
        }
      }
      return result
    },
    engravingDiscount() {
      return Math.ceil((this.engravingFee.price * Number(this.item.customAttributes.find((obj)=>obj.key == '_engravingDiscount').value)))
    },
    variantProps() {
      const engravingFont = this.engravingFont
      const item = this.item
      const title = this.title
      let result = []
      if (item) {
        const variantTitle = get(item, 'variant.title') || title
        if (variantTitle && variantTitle !== 'Default Title') {
          if (item.tags.includes('silicone-ring')) {
            result.push({ label: `Size ${variantTitle}` })
          } else {
            result.push({ label: variantTitle })
          }
        }
        if (item.customAttributes) {
          for (let { key, value } of item.customAttributes) {
            if (key && !key.startsWith('_')) {
              let prop = { label: key, value }
              if ((key === 'font') || (key === 'text')) {
                prop.style = { 'font-family': `Enso ${engravingFont}` }
              }
              result.push(prop)
            }
          }
        }
      }
      return result
    },
    canEditEngraving() {
      const engravingKeys = this.engravingKeys
      const wantsEngraving = this.wantsEngraving
      const variantProps = this.variantProps
      let keys = []
      for (let { label } of variantProps) {
        keys.push(label)
      }
      return wantsEngraving && intersection(keys, engravingKeys).length > 0
    },
    wantsEngraving() {
      return !!get(this.item, 'metafieldsMap.engraving.value')
    },
    hasSleeveFee() {
      let result = false;
      for (let attr of this.item.customAttributes) {
        if (attr.key === 'sleeve') {
          result = true;
        }
      }
      return result;
    }
  },
  methods: {
    ...mapActions('engraving', {
      openEngraving: 'open',
      setEngravingContext: 'setContext',
      deleteEngraving: 'deleteEngraving',
      activeOfferProps: 'activeOfferProps',
    }),
    ...mapMutations('cart', [ 'hideCart' ]),
    async editEngraving() {
      await this.setEngravingContext({
        product: this.item,
        variant: this.item.variant
      })
      this.openEngraving()
      this.hideCart()
    }
  }
}
</script>

<style lang="scss" scoped>
.c-variant-title {
  &__button {
    border: none;
    background: none;
    width: rem(20px);
    height: rem(20px);
    &:hover {
      cursor: pointer;
    }
  }
  &__crud-icon {
    width: rem(12px);
    height: rem(12px);
  }
  &__cta-icon {
    margin-right: rem(10px);
  }
  &__prop-value {
    text-transform: none;
  }
  &__engraving-cta-button {
    border: none;
    animation: alertPulse 2s ease-out;
    animation-iteration-count: 2;
    animation-duration: 1.5s;
    background-color: transparent;
    transition: background 275ms ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-radius: 25px;
    padding: 10px 25px 10px 10px;
    margin-bottom: 0px;

    &:hover {
      //text-decoration: underline;
      cursor: pointer;
      background: rgba(4, 190, 190, .5);
    }

    span {
      margin-right: rem(12px);
    }
  }
}

@keyframes alertPulse {
  0% {background-color: rgba(4, 190, 190, 0);}
  50% {background-color: rgba(4, 190, 190, .5);}
  100% {background-color: rgba(4, 190, 190, 0);}
}
</style>
