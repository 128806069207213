<!--
/****
/* Individual products are loaded with the getProduct mixin.
/* For instructions related to connecting your invetory to
/* Nacelle, please refer to:
/*
/* https://docs.getnacelle.com/getting-started.html#_2-product-settings
/****
-->
<template>
  <div class="p-product-page">
    <div class="nosto_page_type" style="display: none;">
      product
    </div>
    <section class="u-margin-bottom-xx-large">
      <product-info
        :product="product"
        :products="pdpCollectionData.products"
        :bundle-products="bundleProducts"
        :bundle-options="bundleOptions"
        :size.sync="selectedRingSize"
        :variant-available="variantAvailable"
        @engraving="startEngraving"
        @update:variant="currentProductVariant = $event"
      >
        <template v-slot:default="slotProps">
          <!--
            uses scoped slots to pass the props back up to this scope
            @link https://vuejs.org/v2/guide/components-slots.html#Scoped-Slots

            geofence is assumed denied until we get a confirmed positive
            response geoip api

            if a product is NOT in stock, show back in stock notification prompt
          -->
          <template v-if="!variantAvailableLoading">
            <back-in-stock
              v-if="showBackInStock(slotProps, variantAvailable)"
              :product="slotProps.product"
              :variant="slotProps.variant"
            />
            <product-add-to-cart-button
              v-else
              v-bind="{
                ...slotProps,
                disabled: !geofenceAllowed,
                shoppingGivesDetected
              }"
            />
          </template>
          <loader v-else />
          <!--
            don't show this until we have a confirmed negative response from
            geoip api
          -->
          <template v-if="geofenceAllowed === false">
            {{ collectionLabel }} {{ geofenceCodes | formatCodes }}
          </template>
        </template>
        <template
          v-if="product && product.pimSyncSourceProductId"
          v-slot:rating
        >
          <!-- Yotpo Star Ratings -->
          <star-rating
            :product="product"
            class="u-margin-bottom-x-small"
            style="display: inline-block"
          />
        </template>
        <template v-slot:recommendations>
          <div
            v-if="shoppingGivesEnabled"
            id="shopping-gives-widget"
            ref="shoppingGivesWidget"
            class="p-product-page__shopping-gives-widget"
            @click="detectShoppingGivesState"
          />
          <div
            v-if="frequentlyBoughtTogether && !$config.drtv.enabled"
            id="nosto-stl"
          >
            <product-recommendations
              :recommendations="frequentlyBoughtTogether"
            />
          </div>
        </template>
      </product-info>
    </section>
    <a v-utm-adder id="more" />
    <section class="p-product-page__details">
      <tabs :tabs="tabs" :get-product-function="fetchProduct" />
    </section>
    <section>
      <row
        v-if="rowData"
        v-bind="rowData.data"
        :get-product-function="fetchProduct"
      />
    </section>
    <shoppable-instagram
      v-if="!$config.drtv.enabled"
      class="l-container u-margin-bottom-x-large"
      :content="shoppableInstagram"
    />
    <section
      v-if="!$config.drtv.enabled && onTheOtherHand && onTheOtherHand.length"
      class="l-container u-margin-bottom-x-large"
    >
      <h2 class="t-heading-5 u-margin-bottom-medium">
        ON THE OTHER HAND.
      </h2>
      <div id="productpage-nosto-1">
        <product-carousel :collections="onTheOtherHand" />
      </div>
    </section>
    <section
      v-if="product && product.pimSyncSourceProductId"
      class="l-container u-margin-bottom-x-large"
    >
      <client-only>
        <reviews-widget
          v-if="product"
          :product="product"
          :pathname="$route.fullPath"
        />
      </client-only>
    </section>
  </div>
</template>

<script>
import BackInStock from '~/components/nacelle/BackInStock'
import geofenceProduct from '~/mixins/geofenceProduct'
import get from 'lodash/get'
import nmerge from '~/utils/merge-requests'
import nosto from '~/mixins/nosto'
import productMetafields from '~/mixins/productMetafields'
import productPageDetails from '~/mixins/productPageDetails'
import ProductRecommendations from '~/components/products/ProductRecommendations'
import productVariantAvailable from '~/mixins/productVariantAvailable'
import Row from '@/components/layout/Row'
import sezzle from '~/mixins/sezzle'
import loadBundleProducts from '~/mixins/loadBundleProducts'
import { getCollectionFromProps } from '~/mixins/getCollectionFromProps'
import { mapSections } from '@/utils/contentfulHelper'
import { mapState, mapActions } from 'vuex'
import getProductInCard from '~/mixins/getProductInCard'
import productHandle from '~/mixins/productHandle'
export default nmerge({
  name: 'product-page-screen-product',
  components: {
    BackInStock,
    Row,
    ProductRecommendations
  },
  mixins: [
    productHandle,
    getCollectionFromProps('pdpCollection'),
    sezzle(),
    // frequentlyBoughtTogether, onTheOtherHand
    nosto({
      elements: ['productpage-nosto-1', 'productpage-nosto-2', 'nosto-stl']
    }),
    productMetafields,
    productPageDetails,
    loadBundleProducts,
    // defines geofenceAllowed, geofenceCodes
    geofenceProduct,
    // defines variantAvailable
    productVariantAvailable,
    getProductInCard
  ],
  fetchKey() {
    return `product-page-screen-product--${this.product && this.product.id}`
  },
  mounted() {
    if (process.client) {
      // @link https://nuxtjs.org/docs/features/data-fetching#the-fetch-hook
      this.$fetch()
    }
  },
  watch: {
    async product() {
      if (this.product) {
        await this.$nextTick()
        this.setEngravingContext({
          mode: 'pdp',
          product: this.product,
          variant: this.currentProductVariant
        })
      }
    }
  },
  computed: {
    ...mapState('engraving', {
      engravingProduct: ({ product }) => product
    }),
    pdpCollectionTitle() {
      return get(this.pdpCollectionData, 'collection.title')
    },
    engravingVariant: {
      get() {
        return this.$store.state.engraving.variant
      },
      set(variant) {
        this.setEngravingContext({
          mode: 'pdp',
          product: this.product,
          variant,
          open: this.openEngraving
        })
      }
    },
    openEngraving: {
      get() {
        return this.$store.state.engraving.open
      },
      set(open) {
        if (open) {
          this.setEngravingContext({
            mode: 'pdp',
            product: this.product,
            variant: this.currentProductVariant,
            open: this.openEngraving
          })
          this.$nextTick(() => this.$store.dispatch('engraving/open'))
        } else {
          this.$store.dispatch('engraving/close')
        }
      }
    },
    showEngravingScreen() {
      const product = this.engravingProduct
      let result = false
      if (product && product.metafields) {
        result = !!product.metafields.find(obj => {
          return obj.key == 'engraving'
        })
      }
      return result
    },
    isGiftCard() {
      return this.product.vendor.toLowerCase().includes('rise.ai')
    },
    rowData() {
      if (this.productPageContent) {
        let sections = mapSections(this, this.productPageContent.sections)
        for (let section of sections) {
          if (section.contentType == 'row') {
            return section
          }
        }
        return this.productPageContent[1]
      }
    }
  },
  methods: {
    ...mapActions('engraving', {
      setEngravingContext: 'setContext'
    }),
    startEngraving(data) {
      this.currentProductVariant = data.variant
      this.selectedRingSize = parseInt(data.size)
      this.openEngraving = true
    },
    closeEngraving() {
      this.openEngraving = false
    }
  }
  // head() is defined in ~/mixins/productHandle.js
})
</script>

<style lang="scss" scoped>
.p-product-page {
  padding: $section-padding;
  &__details {
    @include themify($themes) {
      background-color: themed('background', 'xx-light');
    }
  }
  &__shopping-gives-widget {
    height: rem(50px);
    overflow: hidden;
    margin-top: rem(20px);
  }
}
</style>
