<!--This is the 404 page-->
<template>
  <div>
    <div
      class="nosto_page_type"
      style="display:none"
    >
      notfound
    </div>
    <div
      v-if="error"
      class="error"
    >
      <div v-if="error.statusCode && error.statusCode === 404">
        <h1 class="t-heading-2 u-margin-bottom-medium">
          Oops! That page can’t be found.
        </h1>
        <p class="t-paragraph-large">
          It looks like nothing was found at this location. Maybe try one of the
          links below.
        </p>
        <div class="suggested-links">
          <btn
            v-for="link in suggestedLinks"
            :key="link.path"
            :url="link.path"
          >
            {{ link.title }}
          </btn>
        </div>
        <div
          v-if="products && products.length"
          class="recos"
        >
          <h2>Featured</h2>
          <div id="productpage-nosto-1">
            <product-carousel
              :products="products"
              :force-mobile="true"
              class="u-margin-bottom-x-large"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <h1>{{ error.statusCode }}</h1>
        <p>{{ error.message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCarousel from '@/components/carousel/ProductCarousel'
import Btn from '@/components/button/Btn'
import get from 'lodash/get'

export default {
  components: { ProductCarousel, Btn },
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      suggestedLinks: [
        { path: '/', title: 'Home' },
        { path: '/collections/silicone-rings', title: 'Shop' },
        { path: '/collections', title: 'Collections' }
      ],
      recos: null
    }
  },
  computed: {
    products() {
      const recos = this.recos
      const recoProducts = get(recos, 'notfound-nosto-1') || []
      return recoProducts.map(product => {
        return {
          nosto: true,
          nostoQueryStr: 'notfound-nosto-1',
          ...product
        }
      })
    }
  },
  async mounted() {
    if (process.client && process.env.NOSTO_ENABLED) {
      try {
        this.recos = await window.nostoClient.viewNotFound({
          elements: ['notfound-nosto-1']
        })
      } catch (err) {
        console.warn(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
}

h2 {
  text-align: center;
  text-transform: uppercase;
}

p {
  text-align: center;
}

a {
  border: 1px solid #fc9900;
  display: inline-block;
  vertical-align: top;
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  transition: 0.14s ease-in;
  padding: 10px 15px;
  margin: 10px;
  border: 1px solid #ff9900;
  border-radius: 5px;
  color: #404040;
  font-family: FuturaPT, futura-pt, sans-serif;
  background: transparent;
  font-size: 19px;
  font-weight: 200;
  letter-spacing: 2px;
  text-decoration: none;
}

a:hover {
  color: white;
  background: #fc9900;
}

.recos {
  margin: 4em auto 2em;
}

::v-deep .c-product-carousel {
  margin-top: 2em;
@Nohemi@eldritch.cafe}

.suggested-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1em;
}

.error {
  padding: $section-padding;
  margin-top: 1em;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
