<template>
  <div
    ref="nextStepFocus"
    class="c-bracelet-builder"
    tabindex="0"
    aria-live="polite"
  >
    <customizer-nav
      :current-step="currentStepIndex"
      :steps="navSteps"
      :close-link="completionRedirect"
      :back-button-aria-label="backButtonAriaLabel"
      :navigation-area-label="navigationAreaLabel"
      :progress-bararia-label="progressBarAriaLabel"
      :exit-button-aria-label="exitButtonAriaLabel"
      @change="handleStepChange"
      @back="decrementStep"
    />

    <div
      class="l-container"
      :aria-label="braceletBuilderAriaLabel"
    >
      <div
        v-if="!loading"
        class="l-grid l-grid--no-gap l-grid--2-col"
      >
        <div class="c-bracelet-builder__product-render l-grid__item">
          <bracelet-price-display
            :mod-price="subtotal | currency"
            :mod-compare-at-price="compareAtSubtotal | currency"
          />
          <div
            class="c-bracelet-builder__product-render-image-container"
            :aria-label="imageAriaLabel"
          >
            <img
              :src="braceletImage.src"
              :alt="braceletImage.altText"
              class="c-bracelet-builder__product-render-image"
            >
            <img
              v-if="selections.clasp && !starwars"
              :src="selections.clasp.featuredMedia.src"
              :alt="selections.clasp.featuredMedia.altText"
              class="c-bracelet-builder__product-render-image c-bracelet-builder__product-render-image--layered"
            >
            <img
              v-if="selections.sleeve && !starwars"
              :src="selections.sleeve.featuredMedia.src"
              :alt="selections.sleeve.featuredMedia.altText"
              class="c-bracelet-builder__product-render-image c-bracelet-builder__product-render-image--layered"
            >
            <img
              v-if="currentStep.personalizationType == 'lightOrDark'"
              src="https://cdn.shopify.com/s/files/1/0018/8612/6193/files/Choose_Your_Side.png?v=1631129982"
              alt="Choose Your Side Rebel Or Empire"
              class="c-bracelet-builder__product-render-image c-bracelet-builder__product-render-image--layered"
            >
            <infusion-message />
          </div>
        </div>
        <div class="l-grid__item">
          <component
            :is="stepComponent"
            v-bind="stepProps"
            @ShowEmailCapture="showEmailCapture"
          >
            <BackInStock
              v-if="showEmailCaptureVal"
              :product="currentProduct"
              :variant="currentProduct.variants[0]"
            />
          </component>
        </div>
      </div>
      <div
        v-else
      >
        <loader />
      </div>
    </div>
  </div>
</template>

<script>
import BandColorSelector from "@/components/braceletBuilder/BandColorSelector"
import BandSizeSelector from "@/components/braceletBuilder/BandSizeSelector"
import ClaspColorSelector from "@/components/braceletBuilder/ClaspColorSelector"
import ClaspFinishSelector from "@/components/braceletBuilder/ClaspFinishSelector"
import BraceletPriceDisplay from '@/components/braceletBuilder/BraceletPriceDisplay'
import CustomizerNav from '@/components/braceletBuilder/CustomizerNav'
import Loader from "@/components/loader/Loader"
import ReviewSelections from "@/components/braceletBuilder/ReviewSelections"
import SleeveSelector from "@/components/braceletBuilder/SleeveSelector"
import InfusionMessage from "@/components/braceletBuilder/InfusionMessage"
import LightOrDark from "@/components/braceletBuilder/LightOrDark";
import BackInStock from "./nacelle/BackInStock";
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
export default {
  name: "BraceletBuilder",
  components: {
    BandColorSelector,
    BandSizeSelector,
    ClaspColorSelector,
    BraceletPriceDisplay,
    ClaspFinishSelector,
    CustomizerNav,
    Loader,
    ReviewSelections,
    SleeveSelector,
    InfusionMessage,
    LightOrDark,
    BackInStock
  },
  props: {
    braceletCollectionHandle: {
      type: String,
      default: null
    },
    claspCollectionHandle: {
      type: String,
      default: null
    },
    sleeveCollectionHandle: {
      type: String,
      default: null
    },
    feeHandles: {
      type: Array,
      default: null
    },
    sleeveLaborChargeSku: {
      type: String,
      default: null
    },
    claspLaborChargeSku: {
      type: String,
      default: null
    },
    defaultBraceletHandle: {
      type: String,
      default: null
    },
    defaultClaspHandle: {
      type: String,
      default: null
    },
    defaultWithSleeve: {
      type: Boolean,
      default: false
    },
    personalizationStep: {
      type: Array,
      default: null
    },
    completionRedirect: {
      type: String,
      default: null
    },
    backButtonAriaLabel: {
      type: String,
      default: ''
    },
    navigationAreaLabel: {
      type: String,
      default: ''
    },
    progressBarAriaLabel: {
      type: String,
      default: ''
    },
    exitButtonAriaLabel: {
      type: String,
      default: ''
    },
    braceletBuilderAriaLabel: {
      type: String,
      default: 'Build custom bracelet by picking a band, clasp, and sleeve.'
    },
  },
  async fetch() {
    await this.resetBuilder()
    await this.loadBraceletProducts();
    await this.loadClaspProducts();
    await this.loadSleeveProducts();
    await this.loadFees();
    await this.loadFirstStep();
  },
  fetchOnServer: false,
  data() {
    return {
      showEmailCaptureVal: false,
    }
  },
  computed: {
    ...mapState('braceletBuilder',[
      'bracelets',
      'clasps',
      'sleeves',
      'fees',
      'sleeveLaborSku',
      'claspLaborSku',
      'selections',
      'currentStep',
      'steps',
      'starWarsType'
    ]),
    ...mapGetters('braceletBuilder',[
      'loading',
      'nextStep',
      'currentStepIndex',
      'compatibleSleeve',
      'subtotal',
      'compareAtSubtotal'
    ]),
    braceletImage() {
      if (this.starWarsType) {
        let currentProduct = null
        for (let bracelet of this.bracelets) {
          if (this.selections.bracelet.handle == bracelet.handle) {
            currentProduct = bracelet;
            break;
          }
        }
        if (this.starWarsType == 'Empire') {
          let image = currentProduct.media[1];
          if (image) {
            return image;
          }
        }
        if (this.starWarsType == 'Rebellion') {
          let image = currentProduct.media[2];
          if (image) {
            return image;
          }
        }
      }
      return this.selections.bracelet.featuredMedia;
    },
    currentProduct() {
      let currentProduct = null;
      for (let bracelet of this.bracelets) {
        if (this.selections.bracelet.handle == bracelet.handle) {
          currentProduct = bracelet;
          break;
        }
      }
      return currentProduct;
    },
    starwars() {
      return this.$route.fullPath.includes('star-wars-bracelet-builder');
    },
    imageAriaLabel() {
      let result = 'Currently showing'
      if (this.selections.bracelet && this.selections.bracelet.featuredMedia.altText) {
        result += " " + this.selections.bracelet.featuredMedia.altText
      }
      if (this.selections.clasp && this.selections.clasp.featuredMedia.altText) {
        result += " " + this.selections.clasp.featuredMedia.altText
      }
      if (this.selections.sleeve && this.selections.sleeve.featuredMedia.altText) {
        result += " " + this.selections.sleeve.featuredMedia.altText
      }
      return result
    },
    stepComponent() {
      const currentStep = this.currentStep
      return currentStep.fields.personalizationType
    },
    stepProps() {
      return {
        bracelets: this.bracelets,
        currentStep: this.currentStep,
        defaultWithSleeve: this.defaultWithSleeve,
        nextStep: this.nextStep,
        selections: this.selections,
        step: this.currentStep,
        completionRedirect: this.completionRedirect,
        steps: this.steps,
        subtotal: this.subtotal
      }
    },
    navSteps() {
      const displaySteps = [ 'Band', 'Clasp', 'Sleeve' ]
      const selections = this.selections
      const steps = this.steps
      const currentStep = this.currentStep
      const nextStep = this.nextStep
      let result = []
      for (let step of (steps || [])) {
        if (displaySteps.includes(step.shortTitle)) {
          //console.log(step, currentStep)
          const active = (
            (step.handle === currentStep.handle) ||
            step.handle.includes(currentStep.shortTitle.toLowerCase())
          )
          let completed = false
          switch (step.shortTitle) {
          case 'Band':
            if (selections.bracelet && !active) {
              completed = true
            }
            break
          case 'Clasp':
            if (
              selections.clasp &&
              nextStep &&
              (step.handle !== nextStep.handle) &&
              !active
            ) {
              completed = true
            } else if (
              selections.clasp &&
              nextStep === null
            ) {
              completed = true
            }
            break
          case 'Sleeve':
            completed = !!(
              (selections.sleeve ||
              (selections.sleeve === false)) &&
              !displaySteps.includes(currentStep.shortTitle) &&
              currentStep.shortTitle != "Finish"
            )
          }
          result.push({
            title: step.shortTitle,
            active: completed || active,
            completed
          })
        }
      }
      return result
    }
  },
  watch: {
    compatibleSleeve: function () {
      if (this.selections.sleeve) {
        return this.update({'sleeve': this.compatibleSleeve})
      }
    }
  },
  beforeDestroy() {
    this.showSiteNavigations();
  },
  mounted() {
    this.hideSiteNavigations();
  },
  methods: {
    ...mapMutations('braceletBuilder', [
      'setBracelets',
      'setClasps',
      'setSleeves',
      'setFees',
      'setSleeveLaborSku',
      'setClaspLaborSku',
      'update'
    ]),
    ...mapActions('braceletBuilder', [
      'resetBuilder',
      'loadSteps',
      'decrementStep',
      'jumpToStep'
    ]),
    ...mapMutations('menu', [
      'showHeader',
      'hideHeader',
      'showFooter',
      'hideFooter',
    ]),
    async handleStepChange({ title }) {
      try {
        await this.jumpToStep(title)
      } catch (err) {
        console.error(err)
      }
    },
    /**
     * Hides global header and footer
     */
    hideSiteNavigations() {
      this.hideHeader()
      this.hideFooter()
    },
    /**
     * Shows global header and footer
     */
    showSiteNavigations() {
      this.showHeader()
      this.showFooter()
    },
    /**
     * Retrieves the bracelet band product(s) from Shopify based on collection
     * handle.
     */
    async loadBraceletProducts() {
      try {
        let bracelets = await this.$api.getCollectionProducts({
          handle: this.braceletCollectionHandle
        })
        // console.log('loadBraceletProducts', bracelets, this.braceletCollectionHandle)
        if (bracelets) {
          this.setBracelets(bracelets)
          this.update({
            bracelet: this.setDefaults(bracelets, this.defaultBraceletHandle)
          })
        }
      } catch (error) {
        console.log(error, 'Unable to load bracelet products.')
      }
    },

    /*
    Retrieves the clasp product(s) from Shopify based on collection handle.
    */
    async loadClaspProducts() {
      try {
        let clasps = await this.$api.getCollectionProducts({
          handle: this.claspCollectionHandle
        })
        if (clasps) {
          this.setClasps(clasps);
          this.setClaspLaborSku(this.claspLaborChargeSku);
          this.update({
            clasp: this.setDefaults(clasps, this.defaultClaspHandle)
          });
        }
      } catch (error) {
        console.log(error, 'Unable to load clasp products')
      }
    },

    /*
    Retrieves the sleeve product(s) from Shopify based on collection handle.
    */
    async loadSleeveProducts() {
      try {
        let sleeves = await this.$api.getCollectionProducts({
          handle: this.sleeveCollectionHandle
        })
        sleeves ? this.setSleeves(sleeves) : null;
        this.setSleeveLaborSku(this.sleeveLaborChargeSku);
      } catch (error) {
        console.log(error, 'Unable to load sleeve products')
      }
    },

    /**
     * Retrieves any fees specified in the instance of the bracelet builder in
     * Contentful. Currently this should just be engraving fee and sleeve fees.
     */
    async loadFees() {
      try {
        let fees = await this.$api.getProducts({
          handles: this.feeHandles
        })
        fees ? this.setFees(fees) : null;
      } catch (error) {
        console.log(error, 'Unable to load fees')
      }
    },

    /**
     * @param products
     * @param defaultProduct
     * @returns {*}
     * Sets the defaults products to load into the selections state object based
     * on product handles stored in contentful. If nothing is set in contentful,
     * the first product object will be used as the default.
     */
    setDefaults(products, defaultProduct = null) {
      //Use contentful default product handle if set.
      if (defaultProduct) {
        let filtered = products.filter((product) => {
          return product.handle === defaultProduct;
        });
        if (filtered.length > 0) {
          //default to the first variant if they exist
          if (filtered[0].variants) {
            return filtered[0].variants[0];
          }
          return filtered[0];
        }
      }
      if (products[0].variants) {
        return products[0].variants[0];
      }
      return products[0];
    },

    loadFirstStep() {
      this.loadSteps(this.personalizationStep);
    },

    showEmailCapture() {
      this.showEmailCaptureVal = true;
    },
  }
}
</script>

<style lang="scss" scoped>
  .c-bracelet-builder {
    &__product-render {
      position: relative;
      @include themify($themes) {
        background-color: themed('background', 'xx-light');
      }
    }
    // &__product-render-image-container {}
    &__product-render-image {
      max-width: 100%;
    }
    &__product-render-image--layered {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
</style>
