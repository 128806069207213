<template comments>
  <div class="c-site-footer">
    <global-footer
      v-if="globalFooterVisible"
      v-bind="mappedFooter"
    >
      <p
        id="a11y-notice"
        class="t-paragraph-small"
      >
        <!--googleoff: index-->
        <!-- eslint-disable vue/no-v-html -->
        <client-only>
          <span v-html="mappedA11yNotice" />
        </client-only>
        <!-- eslint-enable vue/no-v-html -->
        <!--googleon: index-->
      </p>
    </global-footer>
  </div>
</template>

<script>
import get from 'lodash/get'
import getContentItem from '~/mixins/getContentItem'
import GlobalFooter from '@/components/footer/GlobalFooter'
import nmerge from '~/utils/merge-requests'
import { mapSection, contentToHtml } from '~/utils/contentfulHelper'
import { mapState } from 'vuex'
export default nmerge({
  components: {
    GlobalFooter
  },
  mixins: [
    getContentItem('footer', {
      handle: 'globalFooter',
      type: 'footer'
    }),
    getContentItem('a11yNotice', {
      handle: 'a11y-notice',
      // server-side loader can't find the json file if a type is passed
      type: (process.server) ? undefined : 'content'
    })
  ],
  computed: {
    ...mapState('menu', ['globalFooterVisible']),
    mappedFooter() {
      const mappedFooter = mapSection(this, this.footer)
      let result = {}
      if (mappedFooter && mappedFooter.data) {
        result = mappedFooter.data || {}
      }
      return result
    },
    mappedA11yNotice() {
      try {
        const notice = get(this.a11yNotice, 'fields.body')
        return contentToHtml(this, notice)
      } catch (err) {
        console.error(err)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.c-site-footer {
}
</style>
