<template>
  <nav class="c-global-header-screen">
    <a
      class="u-screen-reader-only"
      aria-labelledby="a11y-notice"
      href="#a11y-notice"
    >
      Accessibility Notice
    </a>
    <global-header
      v-if="showNav"
      v-bind="{
        ...mappedHeader.data,
        mobileNav,
        globalHeaderVisible,
        customerName,
        userID,
        searchVisible,
        drawingMenus,
        navVisible,
      }"
      @hideSearch="hideSearch"
      @hideDrawingMenu="hideDrawingMenu"
      @showDrawingMenu="showDrawingMenu"
      @addDrawingMenus="addDrawingMenus"
    >
      <template
        v-if="globalNotification"
        v-slot:banner
      >
        <wysiwyg
          class="c-global-header__banner-item"
          :html="globalNotification"
          link-color="darkbg"
          paragraphSize='t-heading-10'
        />
      </template>
      <template v-slot:cart>
        <main-nav-cart />
      </template>
      <template v-slot:search>
        <search-button />
      </template>
      <template v-slot:mobile-search>
        <mobile-search-button />
      </template>
      <template v-slot:footer>
        <social-media title="Find Us!" />
      </template>
    </global-header>
    <template v-else-if="drtvEnabled">
      <div class="c-header l-grid j-global-header__placeholder-height">
        <div class="l-flex l-flex--justify-start">
          <template v-if="!isMobile">
            <nuxt-link
              v-for="link in basicNavLinks"
              :key="link.i"
              :to="link.url"
              class="t-heading-6 c-header__nav-item"
            >
              {{ link.text }}
            </nuxt-link>
          </template>
          <template v-else>
            <button
              v-if="isMobile"
              type="button"
              class="c-mobile-nav__burger"
              tabindex="1"
              @click="showMenu"
            >
              <span>Menu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="26px"
                height="21px"
                viewBox="0 0 26 21"
                version="1.1"
                aria-hidden="true"
              >

                <title>Show Menu</title>

                <g
                  id="Navigation"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="M-Nav-Home"
                    transform="translate(-19.000000, -62.000000)"
                    fill="#022840"
                    fill-rule="nonzero"
                  >
                    <g
                      id="Header-Nav"
                      transform="translate(0.000000, 44.000000)"
                    >
                      <g
                        id="$ICON/Menu"
                        transform="translate(19.000000, 18.000000)"
                      >
                        <g id="Shape">
                          <path d="M1.36842105,0 C0.612676316,0 0,0.606561095 0,1.35483827 C0,2.10311545 0.612676316,2.70967655 1.36842105,2.70967655 L24.6315789,2.70967655 C25.3873237,2.70967655 26,2.10311545 26,1.35483827 C26,0.606561095 25.3873237,0 24.6315789,0 L1.36842105,0 Z M1.36842105,9.14515834 C0.612676316,9.14515834 0,9.75172621 0,10.5000034 C0,11.2482806 0.612676316,11.8548417 1.36842105,11.8548417 L24.6315789,11.8548417 C25.3873237,11.8548417 26,11.2482806 26,10.5000034 C26,9.75172621 25.3873237,9.14515834 24.6315789,9.14515834 L1.36842105,9.14515834 Z M1.36842105,18.2903235 C0.612676316,18.2903235 0,18.8968845 0,19.6451617 C0,20.3934389 0.612676316,21 1.36842105,21 L24.6315789,21 C25.3873237,21 26,20.3934389 26,19.6451617 C26,18.8968845 25.3873237,18.2903235 24.6315789,18.2903235 L1.36842105,18.2903235 Z" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
            <div
              class="c-mobile-nav__slide u-background-color-base j-mobile-nav__slide"
              :class="{'c-mobile-nav__slide--showing': showMobile}"
            >
              <nav-close-btn
                class="c-mobile-nav__close"
                @click="hideMenu"
              />
              <div class="c-mobile-utility-nav">
                <nuxt-link
                v-for="link in basicNavLinks"
                :key="link.i"
                :to="link.url"
                :class="`c-mobile-utility-nav__link t-heading-6`"
                :tabindex="tabindexStart+2"
                >
                  <title>{{ link.text }}</title>
                  {{ link.text }}
                </nuxt-link>
              </div>
            </div>
          </template>
        </div>
        <div class="l-flex l-flex--justify-center">
          <nuxt-link v-utm-adder to="/">
            <img
              v-if="mappedHeader && mappedHeader.data.image"
              class="c-header__logo"
              :src="mappedHeader.data.image.src"
              :alt="mappedHeader.data.image.alt || 'Enso Logo'"
            >
          </nuxt-link>
        </div>
        <div class="l-flex l-flex--justify-end">
          <main-nav-cart />
        </div>
      </div>
    </template>
    <client-only>
      <cart-screen />
      <engraving-screen-wrapper
        :engraving-context="engravingContext"
        @closeEngraving="closeEngraving"
        @addEngravingToCart="handleAddEngraving"
      />
    </client-only>
  </nav>
</template>

<script>
import engravingScreenMixin from '~/mixins/engravingScreenMixin'
import EngravingScreenWrapper from '~/components/screens/EngravingScreenWrapper'
import get from 'lodash/get'
import getContentItem from '~/mixins/getContentItem';
import GlobalHeader from '@/components/nav/GlobalHeader'
import isEqual from 'lodash/isEqual'
import MainNavCart from '~/components/nacelle/MainNavCart'
import SocialMedia from '@/components/socialMedia/SocialMedia'
import wysiwyg from '@/components/wysiwyg/Wysiwyg'
import { mapSection } from '~/utils/contentfulHelper'
import { mapState, mapMutations } from 'vuex'
import isMobile from '@mixins/isMobile';
import NavCloseBtn from "@/components/nav/sharedComponents/NavCloseBtn.vue";

export default {
  components: {
    CartScreen: () => import(
      /* webpackPrefetch: true */
      '~/components/screens/CartScreen'
    ),
    GlobalHeader,
    MainNavCart,
    MobileSearchButton: () => import(
      /* webpackPreload: true */
      '~/components/nacelle/MobileSearchButton'
    ),
    SearchButton: () => import(
      /* webpackPreload: true */
      '~/components/nacelle/SearchButton'
    ),
    SocialMedia,
    wysiwyg,
    EngravingScreenWrapper,
    NavCloseBtn
  },
  mixins: [
    getContentItem('header', {
      handle: 'globalHeader',
      type: 'header'
    }),
    engravingScreenMixin,
    isMobile
  ],
  data() {
    return {
      mappedHeader: null,
      showMobile: false,
      basicNavLinks: [
        {
          text: 'Shop Now',
          url: '/products/drtv-elements-silicone-ring-thin-set'
        },
        {
          text: 'FAQ',
          url: '/pages/drtv-faq'
        },
        {
          text: 'Help',
          url: '/pages/contact-need-help'
        },
      ],
    }
  },
  computed: {
    ...mapState('vwo', ['mobileNav']),
    ...mapState("menu", ["globalHeaderVisible"]),
    ...mapState('user', ['customerName', 'userID']),
    ...mapState('search', ['searchVisible']),
    ...mapState('nav', ['drawingMenus','navVisible']),
    showNav() {
      const mappedHeader = this.mappedHeader
      const globalHeaderVisible = this.globalHeaderVisible
      const drtvEnabled = this.drtvEnabled
      const $config = this.$config
      return (
        !drtvEnabled &&
        globalHeaderVisible &&
        mappedHeader
      )
    },
    globalNotification() {
      return get(this.mappedHeader, 'data.globalNotification')
    },
    drtvEnabled() {
      return !!get(this.$config, 'drtv.enabled')
    }
  },
  watch: {
    header(value, oldValue) {
      if (!isEqual(value, oldValue)) {
        const vm = this
        const result = mapSection(vm, value)
        if (result) {
          this.mappedHeader = result
        }
      }
    }
  },
  methods: {
    ...mapMutations('search', ['showSearch', 'hideSearch']),
    ...mapMutations('nav', ['hideDrawingMenu', 'showDrawingMenu', 'addDrawingMenus']),
    showMenu() {
      this.showMobile = true;
    },
    hideMenu() {
      this.showMobile = false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.isMobile) {
        this.showMobile = true;
      }
      this.showMobile = false;
    })
  }
}
</script>

<style lang="scss" scoped>
.c-header {
  padding: rem(20px);
  grid-template-columns: 1fr 0fr 1fr;

  &__nav-item {
    margin: 0 rem(30px);
    text-decoration: none;
    padding: rem(10px) 0;
    border-bottom: 5px solid transparent;
    @include themify($themes) {
      color: themed("primary", "base");
    }
    &:hover {
      @include themify($themes) {
        border-bottom: 5px solid themed("primary", "base");
      }
    }
  }

  &__logo {
    width: rem(120px);
  }
  .c-mobile-nav {
    &__slide{
      position: fixed;
      right: 103%;
      top: 0;
      opacity: 0;
      transition: right 0.3s, opacity 0.2s;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      z-index: $zindex-global-header;
      padding: rem(20px) 0;

      &--showing{
        opacity: 1;
        right: 0;
      }
    }

    &__burger{
      @include buttonReset;
      margin-right: rem(25px);

      span{
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0;
        border: 0;
        height: 1px;
        width: 1px;
        overflow: hidden;
      }
    }

    &__close{
      @include buttonReset;
      position: absolute;
      right: rem(20px);
    }
    &__container {
      margin-top: rem(40px);
    }

  }
  .c-mobile-utility-nav{
    @include themify($themes){
      border-bottom: $border themed("foreground", "light");
    }
    padding: $filter-padding;

    &__link{
      @include themify($themes) {
        color: themed("primary", "base");
      }
      display: block;
      margin: 0 rem(15px);
      padding: rem(20px) 0;
      text-decoration: none;
      &:hover{
        @include themify($themes) {
          color: themed("accent", "base");
        }
      }
    }
  }
}
</style>
