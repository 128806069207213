<template>
  <div class="c-engraving-screen">
    <div
      class="c-engraving-screen__ring-count"
    >
      <button
        class="c-engraving-screen__exit-button"
        aria-label="close engraving dialog."
        @click="closeModal"
      >
        &#215;
      </button>
      <div
        v-if="engravingUpsell"
        ref="ringCount"
        class="t-heading-1--light t-heading-6 c-engraving-screen__ring-progress"
      >
        ring {{ ringIndex + 1 }} of {{ numRings }}
      </div>
    </div>

    <div
      ref="wrapper"
      class="c-engraving-screen__wrapper"
      :class="{
        'c-engraving-screen__wrapper--scrollable': !scrolledToBottom
      }"
    >
      <div class="c-engraving-screen__heading">
        <h2
          v-if="mappedEngraving && mappedEngraving.data "
          class="t-heading-5 u-margin-bottom-xx-small"
        >
          {{ mappedEngraving.data.engravingTitle }}
        </h2>
        <!-- eslint-disable vue/no-v-html -->
        <p
          class="t-heading-9 t-heading-9--light u-margin-bottom-small "

          v-html="mappedEngraving.data.engravingParagraph"
        />
        <!-- eslint-enable vue/no-v-html -->
        <p
          v-if="productPrice"
          class="t-heading-6"
        >
          <product-price
            :price="productPrice"
          />
          <span aria-label="plus engraving"> + </span>
          <product-price
            v-if="upsell"
            :price="finalEngravingPrice"
            :compare-at-price="engravingPrice"
          >
            <span
              slot="more"
              aria-hidden="true"
            >Engraving</span>
          </product-price>
          <product-price
            v-else
            :price="engravingPrice"
            :compare-at-price="engravingCompareAtPrice"
          >
            <span
              slot="more"
              aria-hidden="true"
            >Engraving</span>
          </product-price>
        </p>
        <div
          v-if="fontImage"
          class="c-engraving-screen__product-image"
        >
          <font-image
            :src="fontImage.src"
            :alt-text="fontImage.altText"
          />
          <div v-if="engravingUpsell">
            Size: {{ currentRingSize }}
          </div>
        </div>
      </div>
      <div class="c-engraving-screen__step">
        <h3 class="u-margin-bottom-x-small">
          <span class="t-heading-8">STEP 1:</span>
          <span class="t-paragraph">Choose Location</span>
        </h3>
        <radio-button
          :options="locations"
          @radioChange="locationChange"
        />
      </div>
      <div class="c-engraving-screen__step">
        <h3 class="u-margin-bottom-x-small">
          <span class="t-heading-8">STEP 2:</span>
          <span class="t-paragraph">Enter Text</span>
        </h3>
        <engraving-text-input
          v-for="(i,index) in numberOfEngravingLines"
          :key="keyVal + i"
          :line-number="index"
          :length-limit="lengthLimit"
          :value="textVal[index]"
          :focus="cursorPosition.lineLocation == index ? focus : false"
          :cursor-position.sync="cursorPosition"
          :font="`Enso ${eFont}`"
          :allowed-symbols="symbols.map(({ symbol }) => symbol)"
          :required-text="requiredText"
          @input="textUpdate"
          @cursorPosition="updateCursorPosition"
        />
      </div>
      <div class="c-engraving-screen__step">
        <h3 class="t-paragraph u-margin-bottom-x-small">
          Add Symbols (optional):
        </h3>
        <engraving-keyboard
          :font="`Enso ${eFont}`"
          :buttons="symbols"
          @buttonClick="addKeyText"
        />
      </div>
      <div class="c-engraving-screen__step">
        <h3 class="u-margin-bottom-xx-small">
          <span class="t-heading-8">STEP 3:</span>
          <span class="t-paragraph">Choose Font</span>
        </h3>
        <engraving-font-dropdown
          :key="currentRingIndex"
          :value="dropdownFont"
          :fonts="fonts"
          @input="fontChange"
        />
        <div
          v-if="requiredFont"
          class="c-engraving-screen__error"
        >
          * Font is required.
        </div>
      </div>
      <div class="c-engraving-screen__add-to-cart u-margin-bottom-x-small">
        <btn
          :button-size="['thin', 'large']"
          @click="addToCart"
        >
          {{ buttonText }}
        </btn>
      </div>
      <div class="c-engraving-screen__return-btn">
        <cart-flyout-checkout-button
          v-if="upsell"
          checkout-text="Cancle and go to checkout"
          button-type="link"
          @click="addToCart"
        />
        <btn
          v-else
          type="link"
          @click="closeModal"
        >
          Return
        </btn>
      </div>
    </div>
  </div>
</template>
<script>
import Btn from '@/components/button/Btn'
import CartFlyoutCheckoutButton from '~/components/cart/CartFlyoutCheckoutButton'
import FontImage from '@/components/engraving/FontImage'
import get from 'lodash/get'
import getContentItem from '~/mixins/getContentItem'
import getEngravingPrice from '@/mixins/getEngravingPrice'
import intersection from 'lodash/intersection'
import ProductPrice from '@/components/productPrice/ProductPrice'
import RadioButton from '@/components/radioButton/RadioButton'
import { mapSection } from '~/utils/contentfulHelper'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  components: {
    Btn,
    EngravingFontDropdown: () => import(
      /* webpackPrefetch: true */
      '@/components/engraving/EngravingFontDropdown'
    ),
    EngravingKeyboard: () => import(
      /* webpackPrefetch: true */
      '@/components/engraving/EngravingKeyboard'
    ),
    EngravingTextInput: () => import(
      /* webpackPrefetch: true */
      '@/components/engraving/EngravingTextInput'
    ),
    FontImage,
    ProductPrice,
    RadioButton,
    CartFlyoutCheckoutButton
  },
  mixins: [
    getEngravingPrice,
    getContentItem('engravingContent', {
      handle:'engraving-content',
      type: 'engravingContent'
    })
  ],
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    variant: {
      type: Object,
      default: () => ({})
    },
    size: {
      type: [ Number, String ],
      default: 0
    },
    location: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    font: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false,
    },
    addEngravingLabel: {
      type: String,
      default: 'Add to Cart'
    },
    allowedLocations: {
      type: Array,
      default: () => [
        'exterior'
      ]
    },
    currentRingIndex: {
      type: Number,
      default: 0
    },
    ringsLength: {
      type: Number,
      default: 1
    },
    upsell: { // This variable tells us if the user is coming from an upsell, so they get the discount
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      eLocation: '',
      requiredFont: false,
      dropdownFont: '',
      eFont: '',
      textVal: [""],
      keyVal: 10,
      cursorPosition: {charLocation:0, lineLocation:0},
      placeholderText: '',
      focus: false,
      noPaste: false,
      requiredText: false,
      engravingText: {},
      scrolledToBottom: false,
    }
  },
  computed:{
    ...mapState('cart', [
      'engravingUpsell',
      'engravingUpsellPercentDiscount',
    ]),
    symbols() {
      let results = [
        { symbol:'੮', symbolName:'Airplane' },
        { symbol:'੯', symbolName:'Dumbbell' },
        { symbol:'ੰ', symbolName:'Flame' },
        { symbol:'ੱ', symbolName:'Flower' },
        { symbol:'ੲ', symbolName:'Moon and Starts' },
        { symbol:'ੳ', symbolName:'Arm Flex' },
        { symbol:'ੴ', symbolName:'Palm Tree' },
        { symbol:'ੵ', symbolName:'Puzzle Piece' },
        { symbol:'੶', symbolName:'Dog' },
        { symbol:'੷', symbolName:'Cat Face' },
        { symbol:'⚀', symbolName:'Skull' },
        { symbol:'★', symbolName:'Star' },
        { symbol:'✭', symbolName:'Outlined Star' },
        { symbol:'☰', symbolName:'3 Stars' },
        { symbol:'◆', symbolName:'Diamond' },
        { symbol:'❤', symbolName:'Heart' },
        { symbol:'♩', symbolName:'Eighth Note' },
        { symbol:'♪', symbolName:'Connected Eighth Notes' },
        { symbol:'☯', symbolName:'Yin Yang' },
        { symbol:'☶', symbolName:'Mountains' },
        // { symbol:'❧', symbolName:'Christmas Holly' },
        // { symbol:'❄', symbolName:'Snowflake' },
        // { symbol:'☏', symbolName:'Present' },
        { symbol:'✡', symbolName:'Star of David' },
        { symbol:'♓', symbolName:'Ichtchus Fish' },
      ];
      if (this.product && this.product.handle) {
        if (!this.product.handle.includes('dual-tone')) {
          results.push({ symbol:'☨', symbolName:'Cross' },);
        }
      }
      return results;
    },
    mappedEngraving() {
      let result = []
      const vm = this;
      result = mapSection(vm, this.engravingContent)
      return result;
    },
    productPrice() {
      if (this.product && this.product.priceRange || this.product.price) {
        const product = this.product
        return product && product.priceRange
          ? product.priceRange.min
          : product.price
      } else {
        return this.variant.price;
      }

    },
    fonts() {
      const fonts = get(this.engravingData, 'font')
      return fonts || []
    },
    locations() {
      const engravingData = this.engravingData
      const allowedLocations = this.allowedLocations
      let result = []

      if (engravingData && allowedLocations && allowedLocations.length) {
        result = intersection(
          allowedLocations,
          engravingData.location
        )
      } else if (engravingData) {
        result = engravingData.location
      }
      return result
    },
    engravingData() {
      let result = {}
      const field = this.product.metafields ? this.product.metafields.find((obj) => {
        return obj.key === 'engraving'
      }) : false;
      if (field && field.value) {
        result = JSON.parse(field.value)
      }
      return result
    },
    eSize() {
      let result = this.size
      if (!result) {
        const option = this.variant.selectedOptions ? this.variant.selectedOptions.find((obj)=>{
          return obj.name === 'Size'
        }) : false;
        if (option) {
          result = option.value
        }
      }
      return result
    },
    lengthLimit() {
      const engravingData = this.engravingData
      const eLocation = this.eLocation
      const eSize = this.eSize
      return engravingData.characterLimit && eLocation && eSize
        ? engravingData.characterLimit[eLocation][eSize]
        : 10
    },
    fontImage() {
      const media = get(this.product, 'media')
      let result = get(this.product, 'media[0]')
      for (let image of media || []) {
        if (
          image.altText &&
          (
            image.altText.includes(this.eFont) ||
            image.altText.includes(this.eFont.split(" ")[0])
          )
        ) {
          if ((this.locations.length > 1) && this.eLocation) {
            if (
              (this.eLocation.toLowerCase() == "interior") &&
              image.altText.includes("Inside")
            ) {
              result = image
            } else if (
              (this.eLocation.toLowerCase() === "exterior") &&
              image.altText.includes("Outside")
            ) {
              result = image
            }
          } else {
            result = image
          }
        }
      }
      if (result)
        return result
      else
        return this.variant.featuredMedia
    },
    numRings() {
      let numOfRings = this.ringsLength;
      return numOfRings > 1 ? numOfRings : 1
    },
    ringIndex() {
      let numOfRings = this.ringsLength;
      const cri = this.currentRingIndex;
      return numOfRings > 1 && cri >= 0 && cri < numOfRings ? cri : 0;
    },
    buttonText() {
      if (this.upsell &&
          this.ringsLength > this.ringIndex + 1 &&
          this.engravingUpsell) {
        return "Save & Continue"
      } else if (this.upsell &&
          this.ringsLength <= this.ringIndex + 1 &&
          this.engravingUpsell) {
        return "Save & Checkout"
      } else return this.addEngravingLabel
    },
    finalEngravingPrice() {
      if (this.engravingUpsell &&
          this.upsell) {
        return Math.floor(Number(this.engravingPrice) * (1 - this.engravingUpsellPercentDiscount) * 100) / 100;
      } else {
        return this.engravingPrice
      }
    },
    currentRingSize() {
      return  this.variant.selectedOptions.find((obj)=>obj.name = "Size").value
    },
    numberOfEngravingLines() {
      let engravingInfo = this.product.metafieldsMap && this.product.metafieldsMap.engraving ?  this.product.metafieldsMap.engraving.value : null;
      let numberOfLines = engravingInfo && engravingInfo.numberOfLines ? engravingInfo.numberOfLines : 1
      return numberOfLines
    }
  },

  watch: {
    open(open) {
      if (open) {
        this.copyState()
      }
    },
    text() {
      this.copyState()
    },
    font() {
      this.copyState()
    },
    location() {
      this.copyState()
    },
  },
  created() {
    this.copyState()
  },
  async mounted() {
    await this.$nextTick()
    if (this.$refs.wrapper) {
      this.$refs.wrapper.addEventListener('scroll', this.handleScroll)
    }
    this.insertAllEngravingLines()
  },
  beforeDestroy() {
    if (this.$refs.wrapper) {
      this.$refs.wrapper.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    insertAllEngravingLines() {
      let engravingInfo = this.product.metafieldsMap && this.product.metafieldsMap.engraving ?  this.product.metafieldsMap.engraving.value : null;
      let numberOfLines = engravingInfo && engravingInfo.numberOfLines ? engravingInfo.numberOfLines : 1
      for (let i = 0; i < numberOfLines; i++) {
        if (i > this.textVal.length) {
          this.textVal.push("")
        }
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop
      const offsetHeight = e.target.offsetHeight
      const scrollHeight = e.target.scrollHeight
      this.scrolledToBottom = (scrollTop + offsetHeight) === scrollHeight
    },
    copyState() {
      if (this.location) {
        this.eLocation = this.location
      } else {
        this.eLocation = this.allowedLocations[0]
      }
      this.dropdownFont = this.font ? this.font : ''
      if (!this.font && this.engravingData.font) {
        this.eFont =  this.engravingData.font[0]
      } else {
        this.eFont = this.font ? this.font : ''
      }
      this.textVal = this.text.split('\n')
      while (this.metafieldsMap &&
      this.metafieldsMap.engraving &&
      this.textVal.length < this.metafieldsMap.engraving.value.numberOfLines) {
        this.textVal.push('')
      }
    },
    locationChange(event) {
      this.eLocation = event
    },
    closeModal() {
      this.$emit("closeEngraving")
    },
    addKeyText(symbol) {
      let textVal = this.textVal
      let cursorLine = this.cursorPosition.lineLocation
      const chars = this.symbols.map(({ symbol }) => symbol).join('');
      let val = RegExp(`[${chars}]`,'g')
      const specialChars = textVal && textVal[cursorLine] ?
        textVal[cursorLine].match(val) : null;
      let specCharsLength = specialChars && specialChars != null ? specialChars.length : 0;
      const engraveLine = textVal[cursorLine] && textVal[cursorLine].length ?
        textVal[cursorLine] : ''
      let charLength = 1;
      if (symbol.match(val)) {
        charLength++
      }
      if (engraveLine.length <= this.lengthLimit - specCharsLength - charLength) {
        this.textVal[this.cursorPosition.lineLocation] = engraveLine.slice(0, this.cursorPosition.charLocation) +
            symbol +
            engraveLine.slice(this.cursorPosition.charLocation);
        this.cursorPosition.charLocation += 1;
        this.keyVal += 1;
      }

      this.focus = true
    },
    textUpdate(textVal, lineNumber) {
      this.focus = false
      if (textVal.length) {
        this.requiredText = false
      }
      this.textVal[lineNumber] = textVal
      // this.$set(this.textVal, lineNumber, textVal)
      this.focus = true
    },
    updateCursorPosition(event) {
      this.cursorPosition = event
    },
    fontChange(font) {
      this.eFont = font;
      this.dropdownFont = font;
      this.requiredFont = false;
    },
    async addToCart() {
      let discount = 0
      discount = this.engravingUpsellPercentDiscount ? this.engravingUpsellPercentDiscount : discount;
      let engravingUpsellDiscount = discount ? discount : 0;
      const engravingState = {
        item: {
          ...this.product,
          quantity: 1,
          variant: this.variant,
          moreThanOneEngraving: this.ringsLength > 1,
          lastRing: this.ringsLength == this.ringIndex + 1,
        },
        props: [
          { key: 'location', value: this.eLocation },
          { key: 'text', value: this.textVal.join('\n') },
          { key: 'font', value: this.eFont },

        ]
      }
      if (this.upsell && this.engravingUpsell) {
        engravingState.props.push({ key: '_engravingDiscount', value: engravingUpsellDiscount.toString()});
      }
      if (!this.textVal[0].length && this.cursorPosition.lineLocation == 0) {
        this.requiredText = true;
      }
      if (!this.dropdownFont) {
        this.requiredFont = true;
      }
      if (this.textVal[0].length && this.dropdownFont) {
        this.$emit('addEngravingToCart', engravingState);
        this.textVal = []
        this.dropdownFont = ''
        this.eFont = 'lato'

        if (this.upsell && this.ringIndex + 1 == this.ringsLength && this.engravingUpsell) {
          setTimeout(this.checkout, 1000) //time out allows the engraving to get to the cart.
        }
      }
      if (this.ringsLength > 1) {
        this.$refs.ringCount.focus();
      }

      this.dropdownFont = ''
    },
    ...mapMutations('cart', ['setCartError']),
    ...mapActions('checkout', ['processCheckout','resetCheckout']),
    async checkout() {
      this.loading = true
      try {
        await this.processCheckout({ //These are empty but it won't work without the functions
          async beforeCreate() {
            // Allows processing of cart before checkout creation.
          },
          async beforeRedirect() {
            // Allows processing after checkout create and before redirecting.
          }
        })
      } catch (err) {
        console.log(err)
        this.setCartError(err)
        this.loading = false
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.c-engraving-screen {
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: rem(58px);
  &__heading {
    @include themify($themes) {
      border-bottom: $border themed('foreground', 'section-border')
    }
  }
  &__step {
    padding: rem(20px) 0;
  }
  &__wrapper {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: rem(20px) rem(10px);
    &--scrollable {
      @include themify($themes) {
        box-shadow: inset 0px -10px 10px -5px rgba(themed('background', 'dark'), 0.8);
      }
    }
  }
  &__add-to-cart {
    text-align: center;
  }
  &__return-btn {
    text-align: center;
  }
  &__exit-button {
    cursor: pointer;
    background: none;
    border: none;
    line-height: rem(35px);
    font-size: rem(35px);
    width: rem(35px);
    height: rem(35px);
    position: absolute;
    top: rem(10px);
    right: rem(10px);
  }
  &__error {
    color: red;
  }
  &__ring-count {
    position: absolute;
    top: 0;
    display: block;
    background: white;
    text-align: center;
    padding: 10px;
    width: 100%;
    height: rem(58px);
  }
  &__ring-progress {
    padding-top:rem(10px);
  }
}
</style>