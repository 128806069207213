<template>
  <div
    v-if="autoAddIncomplete"
    class="c-cart-offer-gwp"
  >
    <div
      v-if="thresholdReached"
      class="c-cart-offer-gwp__cta"
      :class="[
        'l-flex',
        'l-flex--justify-start'
      ]"
    >
      <img
        v-if="productImage"
        class="c-cart-offer-gwp__product-image"
        :src="productImage.thumbnailSrc"
        :alt="productImage.alt"
      >
      <div
        class="c-cart-offer-gwp__cta-details"
        :class="[
          'l-flex',
          'l-flex--align-start',
          'l-flex--column',
          'l-flex__nowrap'
        ]"
      >
        <template v-if="ctaForMinicartType">
          <div class="c-cart-offer-gwp__cta-banner t-heading-13 t-heading-13--accent">
            {{ cta }}
          </div>
          <p class="c-cart-offer-gwp__product-name t-heading-9 u-margin-bottom-xx-small">
            <span>{{ productName }}</span>
          </p>
          <p class="t-heading-13 t-heading-13--white u-margin-bottom-xx-small">
            <span class="c-cart-offer-gwp__free-gift">
              Free Gift
            </span>
          </p>
          <button
            :class="[
              'c-cart-offer-gwp__add-to-cart-button t-link u-text-underline'
            ]"
            @click="showMiniCart"
          >
            <template v-if="giftAdded">
              Change Color &amp; Size
            </template>
            <template v-else>
              Select Color &amp; Size
            </template>
          </button>
        </template>
        <template v-else>
          <div class="c-cart-offer-gwp__cta-banner t-heading-13 t-heading-13--accent">
            {{ cta }}
          </div>
          <p class="c-cart-offer-gwp__product-name t-heading-9 u-margin-bottom-xx-small">
            <span>{{ productName }}</span>
          </p>
          <p class="t-heading-13 t-heading-13--white u-margin-bottom-xx-small">
            <span class="c-cart-offer-gwp__free-gift">
              Free Gift
            </span>
          </p>
          <button
            :class="[
              'c-cart-offer-gwp__add-to-cart-button t-link u-text-underline'
            ]"
            @click="quickAddToCart"
          >
            Add to Cart
          </button>
        </template>
      </div>
    </div>
    <div
      v-else
      class="c-cart-offer-gwp__offer"
      :class="[
        'l-flex',
        'l-flex--justify-start',
        'l-flex__nowrap'
      ]"
    >
      <cart-offer-cta
        class="c-cart-offer-gwp__copy"
        variant="gwp"
        :message-template="activeMessage"
        :remainder="remainder | currency"
        :collection-name="productName"
      />
      <img
        v-if="productImage"
        class="c-cart-offer-gwp__product-image"
        :src="productImage.thumbnailSrc"
        :alt="productImage.alt"
      >
    </div>
  </div>
</template>

<script>
import CartOfferCta from './CartOfferCta'
import CheckStock from '~/mixins/CheckStock'
import get from 'lodash/get'
import trim from 'lodash/trim'
import vmLock from '~/utils/vm-lock'
import { getCollectionFromProps } from '~/mixins/getCollectionFromProps'
import { getFlag } from '~/utils/userFlags'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  components: {
    CartOfferCta
  },
  mixins: [
    getCollectionFromProps('activeCollection', { reloadOnce: false }),
    CheckStock
  ],
  props: {
    cartSubtotal: {
      type: Number,
      default: 0
    },
    collectionName: {
      type: String,
      required: true
    },
    fallbackCollectionNames: {
      type: Array,
      default: () => []
    },
    cta: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    fallbackMessage: {
      type: String,
      default: ''
    },
    successMessage: {
      type: String,
      default: ''
    },
    threshold: {
      type: Number,
      default: 50
    },
    autoAdd: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      gwpAutoRemoved: false
    }
  },
  computed: {
    ...mapState('cart', ['lineItems']),
    ...mapGetters('cart', {
      productHasGiftWithPurchase :'productHasGiftWithPurchase',
      activeCollection: 'giftWithPurchaseCollection'
    }),
    ctaForMinicartType() {
      const products = this.products
      const unsupportedTypes = [
        'accessory'
      ]
      let result = true
      for (let product of products) {
        if (
          product &&
          product.productType &&
          unsupportedTypes.includes(product.productType.toLowerCase())
        ) {
          result = false
        }
        // if any of the products are not supported, we can't use the mini-cart
        if (result === false) {
          break
        }
      }
      return result
    },
    activeMessage() {
      const primaryOutOfStock = this.primaryOutOfStock
      const message = this.message
      const fallbackMessage = this.fallbackMessage
      return (primaryOutOfStock && fallbackMessage) ? fallbackMessage : message
    },
    primaryCollectionOutOfStock: {
      get() {
        return this.$store.state.cart.giftWithPurchaseCollectionOutOfStock
      },
      set(value) {
        this.$store.commit('cart/gwpCollectionOutOfStock', value)
      }
    },
    lineItem() {
      const productHasGiftWithPurchase = this.productHasGiftWithPurchase
      const lineItems = this.lineItems
      let result = null
      for (let item of lineItems) {
        if (productHasGiftWithPurchase(item)) {
          result = item
        }
      }
      return result
    },
    product() {
      const products = this.products
      const lineItem = this.lineItem
      let selectedProduct = null
      if (
        lineItem &&
        products &&
        products.length
      ) {
        for (let product of products) {
          if (product.handle === lineItem.handle) {
            selectedProduct = product
            break
          }
        }
      } else if (products && products.length) {
        selectedProduct = products[0]
      }
      return selectedProduct
    },
    productImage() {
      return get(this.product, 'featuredImage')
    },
    productName() {
      const productName = get(this.product, 'title')
      const productNameRe = /^(.+)-.*$/
      const productNameMatch = (productName || '').match(productNameRe)
      return (productNameMatch && productNameMatch[1])
        ? trim(productNameMatch[1])
        : ''
    },
    products() {
      const products = get(this.activeCollectionData, 'products')
      return products || []
    },
    remainder() {
      const subtotal = this.cartSubtotal
      const threshold = this.threshold
      return threshold - subtotal
    },
    thresholdReached() {
      const subtotal = this.cartSubtotal
      const threshold = this.threshold
      if ((subtotal < threshold) && this.lineItem) {
        // i was not expecting this in a computed
        this.removeLineItem(this.lineItem)
      }
      return subtotal >= threshold
    },
    giftAdded() {
      return !!this.lineItem
    },
    shouldAutoAdd() {
      const autoAdd = this.autoAdd
      const primaryOutOfStock = this.primaryCollectionOutOfStock
      return autoAdd && !primaryOutOfStock
    },
    autoAddIncomplete() {
      const products = this.products
      const removed = this.gwpAutoRemoved
      const autoAdd = this.shouldAutoAdd
      const giftAdded = this.giftAdded
      let result = true
      if (autoAdd && (giftAdded || removed || (products.length === 0))) {
        result = false
      }
      return result
    }
  },
  watch: {
    async thresholdReached() {
      await vmLock(this, '_addingToCart', this.autoAddToCart)
    },
    async products() {
      await vmLock(this, '_addingToCart', this.autoAddToCart)
    },
    autoAddIncomplete(value) {
      console.log('[gwp] incomplete', value)
    }
  },
  async mounted() {
    this.gwpAutoRemoved = await getFlag('gwpauto-removed')
  },
  methods: {
    ...mapActions('cart', ['addLineItemWithProps', 'removeLineItem']),
    showMiniCart() {
      this.$emit('select', {
        lineItemProps: [
          { key: '_lineGiftWithPurchase' }
        ]
      })
    },
    async quickAddToCart() {
      const products = this.products
      const product = get(products, '[0]')
      const variant = get(products, '[0].variants[0]')
      if (product && variant) {
        try {
          await this.addLineItemWithProps({
            item: { ...product, variant },
            props: [
              { key: '_lineGiftWithPurchase' }
            ]
          })
        } catch (err) {
          console.error(err)
        }
      }
    },
    async autoAddToCart() {
      const giftRemoved = this.gwpAutoRemoved = await getFlag('gwpauto-removed')
      const thresholdReached = this.thresholdReached
      const autoAdd = this.shouldAutoAdd
      const giftAdded = this.giftAdded
      const shouldAutoAdd = (
        !giftAdded && !giftRemoved && autoAdd && thresholdReached
      )
      /*
      console.log('[gwp] checking autoadd condition', {
        autoAdd,
        giftAdded,
        giftRemoved,
        thresholdReached,
        result: shouldAutoAdd
      })
      */
      if (shouldAutoAdd) {
        const products = this.products
        const product = get(products, '[0]')
        const variant = get(products, '[0].variants[0]')
        const available = await this.checkStockAvailable({ product, variant })
        console.log('[gwp] stock available?', {
          product,
          variant,
          available
        })
        if (!available) {
          this.primaryCollectionOutOfStock = true
        }
        if (product && variant && available) {
          try {
            await this.addLineItemWithProps({
              item: { ...product, variant },
              props: [
                { key: '_lineGiftWithPurchase' },
                { key: '_gwpauto' }
              ]
            })
          } catch (err) {
            console.error(err)
          }
        } else {
          console.warn('[gwp] not auto-adding', { product, variant, available })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-cart-offer-gwp {
  width: 100%;
  height: 100%;
  &__cta-details {
    height: 100%;
    flex: 1;
  }
  &__cta {
    @include themify($themes) {
      background-color: themed('background', 'xx-light')
    }
    position: relative;
  }
  &__cta-banner {
    position: absolute;
    top: rem(-23px);
    left: 0;
    width: 100%;
    padding: 0 rem(20px);
    text-transform: uppercase;
    font-weight: bold;
  }
  &__offer,
  &__cta {
    height: 100%;
    padding: rem(10px);
  }
  &__copy {
    margin-right: auto;
  }
  &__product-image {
    width: rem(75px);
    margin-right: rem(20px);
  }
  &__free-gift {
    background-color: $cyan;
    border-radius: 8px;
    padding: rem(1px) rem(7.5px);
    text-transform: uppercase;
  }
  &__add-to-cart-button {
    @include buttonReset;
    text-align: left;
    cursor: pointer;
  }
}
</style>
