<template>
  <guided-quiz
    v-if="mappedRingGuidedContent"
    v-bind="mappedRingGuidedContent.data"
    :title="guidedQuizTitle"
    :results="productData | clone"
    :mapped-sections="mappedSections"
  >
    <template v-for="(section, index) in mappedSections">
      <component
        :is="section.contentType"
        :id="section.handle"
        :key="index"
        v-bind="section.data"
      />
    </template>
  </guided-quiz>
</template>

<script>
import clone from 'lodash/clone'
import GuidedQuiz from '@/components/guidedQuiz/GuidedQuiz'
import FeaturedProducts from "./FeaturedProductsScreen"
import getContentItem from '~/mixins/getContentItem'
// import isEqual from 'lodash/isEqual'
import { mapSection } from "~/utils/contentfulHelper"

export default {
  name: "QuidedQuizScreen",
  components:{
    GuidedQuiz,
    FeaturedProducts,
  },
  filters: {
    clone(data) {
      return clone (data)
    }
  },
  mixins: [
    getContentItem('guidedQuiz', {
      handle: 'guided-quiz',
      type: 'guidedQuiz'
    }),
  ],
  props:{
    productData: {
      type: Array,
      default: null
    },
    mappedSections: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    mappedRingGuidedContent() {
      const value = this.guidedQuiz
      const result = value ? mapSection(this, value) : null
      return result
    },
    guidedQuizTitle() {
      const value = this.guidedQuiz
      return value && value.title
    }
  },
}
</script>
<style scoped></style>
