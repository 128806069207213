<template>
  <div>
    <div class="c-filter-bar">
      <div class="c-filter-bar__container">
        <div 
          v-if="visible"
          class="l-flex"
        >
          <div
            v-for="filter in usefulFilters"
            :key="filter.property.label"
          >
            <button
              :id="filter.property.label.toLowerCase()"
              class="c-filter-bar__filter-button"
              :class="`c-filter-bar__filter-button${property.label == filter.property.label ? '--selected' : ''}`"
              @click="showFilterOptions(filter)"
            >
              <span>{{ filter.property.label }} {{ selectedFilterCount(filter) }}</span>
              <svg
                width="11"
                height="8"
                viewBox="0 0 11 8"
                fill="none"
                :style="`${showFilter && activePanel == filter.property.label ? 'transform: rotate(180deg)' :''}`"
              >
                <path
                  d="M5.5 7.07002L0 1.57002L1.1 0.47002L5.5 4.87002L9.9 0.47002L11 1.57002L5.5 7.07002Z" 
                  fill="#022840"
                />
              </svg>
            </button>
            <div
              v-if="showFilter && activePanel == filter.property.label"
              class="c-filter-panel"
            >
              <component
                :is="filterComponent"
                :class="property"
                direction="row"
                v-bind="{ property, values, activeFilter }"
                @input="toggleFilter"
              />
            </div>
          </div>
          <button
            class="c-filter-bar__filter-button"
            @click="$emit('click', $event)"
          >
            <span>All Filters</span>
            <span v-if="filterCount > 0">
              ({{ filterCount }})
            </span>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 0.77002C5.26522 0.77002 5.51957 0.875377 5.70711 1.06291C5.89464 1.25045 6 1.5048 6 1.77002V3.77002C6 4.03524 5.89464 4.28959 5.70711 4.47713C5.51957 4.66466 5.26522 4.77002 5 4.77002C4.73478 4.77002 4.48043 4.66466 4.29289 4.47713C4.10536 4.28959 4 4.03524 4 3.77002V1.77002C4 1.5048 4.10536 1.25045 4.29289 1.06291C4.48043 0.875377 4.73478 0.77002 5 0.77002ZM5 10.77C5.26522 10.77 5.51957 10.8754 5.70711 11.0629C5.89464 11.2504 6 11.5048 6 11.77V13.77C6 14.0352 5.89464 14.2896 5.70711 14.4771C5.51957 14.6647 5.26522 14.77 5 14.77C4.73478 14.77 4.48043 14.6647 4.29289 14.4771C4.10536 14.2896 4 14.0352 4 13.77V11.77C4 11.5048 4.10536 11.2504 4.29289 11.0629C4.48043 10.8754 4.73478 10.77 5 10.77ZM13 5.77002C13.2652 5.77002 13.5196 5.87538 13.7071 6.06291C13.8946 6.25045 14 6.5048 14 6.77002V8.77002C14 9.03524 13.8946 9.28959 13.7071 9.47713C13.5196 9.66466 13.2652 9.77002 13 9.77002C12.7348 9.77002 12.4804 9.66466 12.2929 9.47713C12.1054 9.28959 12 9.03524 12 8.77002V6.77002C12 6.5048 12.1054 6.25045 12.2929 6.06291C12.4804 5.87538 12.7348 5.77002 13 5.77002ZM17 7.77002H14H17Z" fill="#022840"/>
              <path d="M17 2.77002H6M4 2.77002H1M17 12.77H6M4 12.77H1M12 7.77002H1M17 7.77002H14M5 0.77002C5.26522 0.77002 5.51957 0.875377 5.70711 1.06291C5.89464 1.25045 6 1.5048 6 1.77002V3.77002C6 4.03524 5.89464 4.28959 5.70711 4.47713C5.51957 4.66466 5.26522 4.77002 5 4.77002C4.73478 4.77002 4.48043 4.66466 4.29289 4.47713C4.10536 4.28959 4 4.03524 4 3.77002V1.77002C4 1.5048 4.10536 1.25045 4.29289 1.06291C4.48043 0.875377 4.73478 0.77002 5 0.77002ZM5 10.77C5.26522 10.77 5.51957 10.8754 5.70711 11.0629C5.89464 11.2504 6 11.5048 6 11.77V13.77C6 14.0352 5.89464 14.2896 5.70711 14.4771C5.51957 14.6647 5.26522 14.77 5 14.77C4.73478 14.77 4.48043 14.6647 4.29289 14.4771C4.10536 14.2896 4 14.0352 4 13.77V11.77C4 11.5048 4.10536 11.2504 4.29289 11.0629C4.48043 10.8754 4.73478 10.77 5 10.77ZM13 5.77002C13.2652 5.77002 13.5196 5.87538 13.7071 6.06291C13.8946 6.25045 14 6.5048 14 6.77002V8.77002C14 9.03524 13.8946 9.28959 13.7071 9.47713C13.5196 9.66466 13.2652 9.77002 13 9.77002C12.7348 9.77002 12.4804 9.66466 12.2929 9.47713C12.1054 9.28959 12 9.03524 12 8.77002V6.77002C12 6.5048 12.1054 6.25045 12.2929 6.06291C12.4804 5.87538 12.7348 5.77002 13 5.77002Z" stroke="#022840" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <div class="c-filter__sort-container">
          <button
            class="c-filter-bar__filter-button"
            :class="`c-filter-bar__filter-button${showSortBy ? '--selected' : ''}`"
            @click="showSortByOptions()"
          >
            <span>Sort by: {{ sortBy.label }}</span>
            <svg 
              width="11" 
              height="8" 
              viewBox="0 0 11 8" 
              fill="none"
              :style="`${showSortBy ? 'transform: rotate(180deg)' :''}`"
            >
              <path
                d="M5.5 7.07002L0 1.57002L1.1 0.47002L5.5 4.87002L9.9 0.47002L11 1.57002L5.5 7.07002Z"
                fill="#022840"
              />
            </svg>
          </button>
          <div 
            v-if="showSortBy" 
            class="c-filter__sort-menu"
          >
            <div class="c-filter__sort-container">
              <div
                v-for="sort in sortByFilters" 
                :key="sort.i"
              >
                <label
                  :for="sort.value"
                  class="c-radio__label"
                >
                  <input
                    v-model="sortBy"
                    :value="sort.value"
                    type="radio"
                    class="c-radio__input"
                    @input="$emit('sortProducts', sort.value), showSortBy = false"
                  >
                  {{ sort.label }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <client-only>
        <filter-modal-screen
          :products="products"
          :modal-open="modalOpen"
          :property-filters="propertyFilters"
          :quick-filter="quickFilter"
          :align="modalAlign"
          :is-refinement-filter-below-nav="isRefinementFilterBelowNav"
          @clear="$emit('clear', $event)"
          @close="$emit('close', $event)"
          @updated="$emit('updated', $event)"
          @update:filter-count="filterCount = $event"
          @update:filters="filters = $event"
          @active-filters="activeFilters = $event"
        />
      </client-only>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Accordion from '@/components/accordion/Accordion'
import ActiveFilters from '@/components/filter/ActiveFilters'
import clone from 'lodash/clone'
import ColorFilter from '@/components/filter/ColorFilter'
import GenderFilter from '@/components/filter/GenderFilter'
import EngravingFilter from '@/components/filter/EngravingFilter'
import isMobile from '@mixins/isMobile';
import queryString from 'query-string'
import RadioInput from '~/components/nacelle/RadioInput'
import RefinementFilterScreenMixin from '@/mixins/refinementFilterScreen'
import SizeFilter from '@/components/filter/SizeFilter'
import sleep from '~/utils/sleep'
import WidthFilter from '@/components/filter/FormFactorFilter'

export default {
  components: {
    FilterModalScreen: () => import('~/components/screens/FilterModalScreen'),
    EngravingFilter,
    Accordion,
    ActiveFilters,
    ColorFilter,
    WidthFilter,
    GenderFilter,
    SizeFilter,
    RadioInput,
  },
  mixins: [
    RefinementFilterScreenMixin,
    isMobile
  ],
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    products: {
      type: Array,
      required: true
    },
    modalOpen: {
      type: Boolean,
      required: true
    },
    isRefinementFilterBelowNav: {
      type: Boolean,
      default: false
    },
    modalAlign: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      filterCount: 0,
      filters: [],
      quickFilter: {},
      property: {},
      values: [],
      activeFilters: [],
      sortBy: 'Relevance',
      activePanel: null,
      showSortBy: false,
      selectedFilterCounts: {}
    }
  },
  computed: {
    showFilter() {
      const showSortBy = this.showSortBy
      const activePanel = this.activePanel
      let result = false
      if (activePanel && !showSortBy) {
        result = true
      }
      return result
    },
    usefulFilters() {
      const isMobile = this.isMobile
      const filters = this.filters
      let result = []
      for (let filter of filters) {
        if (!isMobile && this.usefulFilter(filter)) {
          result.push(filter)
        }
      }
      return result
    },
    propertyFilters() {
      return [
        { field: 'color', label: 'Color' },
        { field: 'finish', label: 'Finish' },
        { field: 'tone', label: 'Tone' },
        { field: 'width', label: 'Width' },
        { field: 'size', label: 'Size' },
        { field: 'gender', label: 'Gender' },
        { field: 'engraving', label: 'Engraveable' },
      ]
    },
    sortByFilters() {
      return [
        {value: 'relevance',label: 'Relevance'},
        {value: 'low',label: 'Price: Low to High'},
        {value: 'high',label: 'Price: High to Low'},
        {value: 'new',label: 'Newest first'},
        {value: 'old',label: 'Oldest first'},
      ]
    }
  },
  watch: {
    activeFilters: {
      handler() {
        this.setSelectedFilterCounts()
      },
      deep: true,
    },
    filters() {
      this.setSelectedFilterCounts()
    }
  },
  created() {
    if (process.browser) {
      this.activeFilters = this.readFiltersFromQueryParams({
        omit: [ 'priceRange' ]
      })
    }
  },
  methods: {
    usefulFilter(filter) {
      const values = filter.values
      if (values.length >= 1) {
        let emptyFilter = filter.values.filter(e => e[filter.property.label] == "")
        if (emptyFilter.length == filter.values.length) {
          return false
        }
      }
      return !!(values && (values.length > 1))
    },
    showFilterOptions(filter) {
      const closeFilterMenu = (e) => {
        const filterPanel = $(e.target).parents('.c-filter-panel')
        // don't toggle the view if the user is changing the filter options
        if (filterPanel.length === 0) {
          this.showFilterOptions(null)
        } else {
          // bind one more handler for the next click
          $(document).one('click', closeFilterMenu)
          $(this.$el).closest('.l-container').one('click', closeFilterMenu)
        }
      }
      if (
        (filter === null) ||
        (filter && (this.activePanel === filter.property.label))
      ) {
        // filter menu OFF
        this.activePanel = null
        this.property = {}
        this.values = []
      } else if (!this.showFilter && filter && process.client) {
        // clear state
        this.showFilterOptions(null)
        // filter menu ON
        this.showSortBy = false
        this.activePanel = filter.property.label
        this.property = clone(filter.property)
        this.values = clone(filter.values)
        sleep(0).then(() => {
          $(document).one('click', closeFilterMenu)
          $(this.$el).closest('.l-container').one('click', closeFilterMenu)
        })
      }
    },
    showSortByOptions() {
      if (this.showSortBy) {
        this.showSortBy = false;
      } else {
        this.property = ''
        this.showSortBy = true
      }
    },
    toggleFilter(filter) {
      this.quickFilter = filter
    },
    setSelectedFilterCounts() {
      this.filters.forEach(filter => {
        const item = this.activeFilters.find(activeFilter => activeFilter.property == filter.property.field)
        if (item) {
          if (filter.property.field == 'color') {
            const type = filter.property.label == 'Color' ? 'hue' : filter.type
            this.selectedFilterCounts[filter.property.label] = item.values.filter(value => value.includes(type + ':')).length
          } else {
            this.selectedFilterCounts[filter.property.label] = item.values.length
          }
        } else {
          this.selectedFilterCounts[filter.property.label] = 0
        }
      })
    },
    selectedFilterCount(filter) {
      if (this.selectedFilterCounts.hasOwnProperty(filter.property.label) && this.selectedFilterCounts[filter.property.label] > 0) {
        return '(' + this.selectedFilterCounts[filter.property.label] + ')'
      } else {
        return ''
      }
    },
    readFiltersFromQueryParams(options) {
      const { omit } = options || { omit: [] }
      let result = []
      let parsed = Object.entries(
        queryString.parse(location.search, { arrayFormat: 'comma' })
      )
      parsed = Object.fromEntries(
        parsed.map(filter => {
          if (typeof filter[1] === 'string') {
            filter[1] = [filter[1]]
          }
          return filter
        })
      )
      const propertyFilters = [
        ...(this.propertyFilters || []),
        { field: 'priceRange' }
      ]
      let filtersFromUrl = []
      for (let { field } of propertyFilters) {
        let filter = {
          property: field,
          values: parsed[field]
        }
        if (field === 'priceRange' && parsed[field]) {
          filter.values = [
            parsed[field]
          ]
        }
        if (
          filter.values !== null &&
          filter.values !== undefined &&
          filter.values.length > 0 &&
          !omit.includes(filter.property)
        ) {
          filtersFromUrl.push(filter)
        }
      }
      if (filtersFromUrl.length > 0) {
        result = filtersFromUrl
      }
      return result
    },
  }
}
</script>

<style lang="scss" scoped>
.c-filter-bar {
  @include themify($themes) {
    border: $border themed('foreground', 'section-border');
  }
  position: relative;
  box-shadow: $box-shadow;
  display: flex;
  flex-wrap: nowrap;
  // overflow: auto;
  &__filter-button {
    display: inline-flex;
    align-items: center;
    gap: rem(5px);
    font-size: rem(14px);
    font-weight: 500;
    white-space: nowrap;
    letter-spacing: rem(2px);
    line-height: rem(21px);
    max-width: 100%;
    width: max-content;
    padding: rem(10px) rem(30px);
    margin: rem(10px) rem(5px);
    border-radius: rem(100px);
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    @include themify($themes) {
      background-color: themed('background', 'xx-light');
    }
    
    @media screen and (max-width: 769px) {
      padding: rem(10px) rem(20px);
      margin: rem(7.5px) rem(5px);
      border-radius: rem(20px);
    }

    &--selected {
      border-radius: 0px;
      background-color: white!important;
    }
  }
  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 rem(10px);
    @media screen and (max-width: 900px) {
      flex-direction: row-reverse;
    }
  }
}

.c-filter__sort{
  &-container {
      position: relative;
  }
  &-menu {
    z-index:9;
    position: absolute;
    right: 0;
    top: rem(50px);
    @media screen and (max-width: 900px) {
      left: 0;
    }
    max-width: rem(210px);
    width: max-content;
    padding: rem(10px) rem(20px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    @include themify($themes) {
      background-color: themed('background', 'base');
    }
    @media screen and (max-width: 480px) {
      margin-left: 0;
      width: 100%;
      max-width: unset;
    }
  }
}
.c-radio {
  &__label {
    font-size: rem(18px);
    font-weight: 300;
    letter-spacing: rem(.5px);
    line-height: rem(21px);
    display: flex;
    align-items: center;
    margin-bottom: rem(10px);
  }
  &__input {
    margin-right: rem(10px);
    width: rem(20px);
    height: rem(20px);
  }
}

.c-filter-panel {
  position: absolute;
  z-index: 9;
  top: rem(50px);
  max-width: rem(550px);
  margin-left: rem(10px);
  padding: rem(10px) rem(20px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  @include themify($themes) {
    background-color: themed('background', 'base');
  }
  @media screen and (max-width: 480px) {
    margin-left: 0;
    width: 100%;
    max-width: unset;
  }
}
</style>
