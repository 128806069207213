<template>
  <div class="c-accordions">
    <div class="l-container">
      <h2
        v-if="title"
        :class="titleClass"
      >
        {{ title }}
      </h2>
      <template v-if="$attrs.sections">
        <div
          v-for="section in $attrs.sections"
          :key="section.title"
          class="c-accordions__section"
        >
          <accordion
            v-bind="section"
            :starting-state="false"
          >
            <wysiwyg
              v-if="section.description"
              :html="section.description"
            />
          </accordion>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/accordion/Accordion';
import Wysiwyg from '@/components/wysiwyg/Wysiwyg';
import PageContentMixin from '~/mixins/PageContentMixin';
import isMobile from '@mixins/isMobile';

export default {
  components: {
    Accordion,
    Wysiwyg
  },
  mixins: [
    PageContentMixin,
    //this.isMobile is true screen width < 900px
    isMobile
  ],
  props: {
    textAlignment: {
      type: String,
      default: "left"
    },
    mobileTextAlignment: {
      type: String,
      default: "left"
    },
    title: {
      type: String,
      default: null
    },
    titleTypography: {
      type: String,
      default: "t-heading-4"
    },
    titleMarginBottom: {
      type: String,
      default: "medium"
    },
    titleColor: {
      type: String,
      default: ""
    },
  },
  computed: {
    titleClass() {
      let results = [''];
      results.push(this.titleTypography);
      if (this.titleColor) {
        results.push(this.titleTypography + '--' + this.titleColor);
      }
      if(this.subtitle || this.description || this.column) {
        results.push('u-margin-bottom-' + this.titleMarginBottom);
      }
      if (this.isMobile) {
        if (this.mobileTextAlignment) {
          results.push("u-text-align-" + this.mobileTextAlignment)
        }
      } else {
        results.push("u-text-align-" + this.textAlignment)
      }
      return results;
    }
  }
}
</script>

<style lang="scss" scoped>
.c-accordions {
  padding: $section-padding;
}
</style>
