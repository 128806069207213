<!--
/****
/* Product collections are loaded with the getCollection mixin.
/* For information about creating collections, please refer to:
/*
/* https://docs.getnacelle.com/nuxt/collections.html#adding-content-to-collections-pages
/****
-->
<template>
  <div
    class="p-collection"
    :class="pageClassNames"
  >
    <template v-if="collection">
      <div
        class="nosto_page_type"
        style="display:none"
      >
        category
      </div>
      <component
        :is="mappedPageSections[0].contentType"
        v-if="mappedPageSections[0] && mappedPageSections[0].contentType && !isQuiz"
        :id="mappedPageSections[0].handle"
        v-bind="mappedPageSections[0].data"
        :get-product-function="fetchProduct"
      />
    </template>
    <filter-bar
      v-if="page && !page.fields.hideFilterBar"
      :visible="!isQuiz"
      :products="products"
      :modal-open="modalOpen"
      @clear="filtered = false"
      @click="toggleFilterModal"
      @close="modalOpen = false"
      @updated="filteredData = $event"
      @sortProducts="sortProducts($event)"
    />
    <template v-if="collection">
      <component
        :is="mappedPageSections[1].contentType"
        v-if="visibilityMap.modContentA"
        :id="mappedPageSections[1].handle"
        v-bind="mappedPageSections[1].data"
        :get-product-function="fetchProduct"
      />
      <collections-container
        v-if="visibilityMap.collectionsContainerA"
        :key="`${collectionHandle}-A--${filteredProducts.length}`"
        :handle="collectionHandle"
        :products="filteredProducts"
        :sections="page.sections"
        :filtered="filtered || singleGrid"
        :sorted="sorted"
        :first-instance="true"
        :collection-order="collectionOrder"
        @update:pagination="updatePagination"
      />
      <component
        :is="mappedPageSections[1].contentType"
        v-if="visibilityMap.contentA"
        :id="mappedPageSections[1].handle"
        v-bind="mappedPageSections[1].data"
        :get-product-function="fetchProduct"
      />
      <collections-container
        v-if="visibilityMap.collectionsContainerB"
        :key="`${collectionHandle}-B--${filteredProducts.length}`"
        :handle="collectionHandle"
        :products="filteredProducts"
        :sections="page.sections"
        :filtered="filtered"
        :first-instance="false"
        :collection-order="collectionOrder"
        @update:pagination="updatePagination"
      />
      <template v-for="(section, index) in mappedPageSections.slice(2)">
        <component
          :is="section.contentType"
          v-if="section"
          :id="section.handle"
          :key="index"
          v-bind="section.data"
          :get-product-function="fetchProduct"
        />
      </template>
      <guided-quiz-screen
        v-if="isQuiz"
        :product-data="filteredProducts"
        :mapped-sections="mappedPageSections"
      />
    </template>
  </div>
</template>

<script>
import Btn from '@/components/button/Btn'
import CollectionsContainer from '@/components/collection/CollectionsContainer'
import CollectionSortAndFilter from '~/mixins/CollectionSortAndFilter'
import FilterBar from '~/components/FilterBar'
import get from 'lodash/get'
import getCollection from '~/mixins/getCollection'
import getPage from '~/mixins/getPage'
import getProductInCard from '~/mixins/getProductInCard'
import GuidedQuizScreen from '~/components/screens/GuidedQuizScreen'
import HeroBanner from '~/components/screens/HeroScreen'
import InputForm from '~/components/InputForm'
import nmerge from '~/utils/merge-requests'
import ObserveEmitter from '~/components/nacelle/ObserveEmitter'
import ProductGrid from '~/components/nacelle/ProductGrid'
import Row from '@/components/layout/Row'
import viewEvent from '~/mixins/viewEvent'
import { mapGetters } from 'vuex'
import { mapSections } from '~/utils/contentfulHelper'

export default nmerge({
  components: {
    SuperCollectionPage: () => import('~/components/SuperCollectionPage'),
    FilterBar,
    HeroBanner,
    Row,
    InputForm,
    Btn,
    ProductGrid,
    ObserveEmitter,
    CollectionsContainer,
    GuidedQuizScreen
  },
  data() {
    return {
      filtered: false,
      modalOpen: false,
      filteredData: null,
      sortBy: null,
      sorted: false,
      recos: null
    }
  },
  mixins: [
    // defines this.products
    getPage({ error: false }),
    getCollection({ orderProp: 'collectionOrder' }),
    viewEvent('collection'),
    // defines this.getFilteredProducts
    CollectionSortAndFilter,
    // fetchProduct
    getProductInCard,
  ],
  computed: {
    ...mapGetters('space', ['getMetatag']),
    singleGrid() {
      return get(this.page, 'fields.singleGrid')
    },
    visibilityMap() {
      const mappedPageSections = this.mappedPageSections
      const collection = this.collection
      const isQuiz = this.isQuiz
      const page = this.page
      const products = this.products
      const singleGrid = this.singleGrid
      const isFiltered = this.filtered
      const filtered = isFiltered || singleGrid
      return {
        modContentA: (
          mappedPageSections &&
          mappedPageSections[1] &&
          mappedPageSections[1].contentType &&
          (collection.handle === 'mod') &&
          !isQuiz
        ),
        contentA: (
          mappedPageSections[1] &&
          mappedPageSections[1].contentType &&
          !filtered &&
          (collection.handle != 'mod') &&
          !isQuiz
        ),
        collectionsContainerA: (
          page &&
          page.sections &&
          products &&
          (products.length > 0) &&
          !isQuiz
        ),
        collectionsContainerB: (
          page &&
          page.sections &&
          products &&
          (products.length > 0) &&
          !filtered &&
          !isQuiz
        ),
      }
    },
    pageClassNames() {
      const page = this.page
      let result = []
      if (page) {
        const pageHandle = get(page, 'handle')
        const pageTheme = get(page, 'fields.pageTheme')
        result.push(
          `p-collection--${pageHandle}`,
          `theme-${pageTheme}`
        )
      }
      return result
    },
    mappedPageSections() {
      let result = []
      const vm = this
      if (this.page) {
        result = mapSections(vm, this.page.sections)
      }
      return result
    },
    filteredProducts() {
      let products = this.getFilteredProducts({
        collectionHandle: this.collectionHandle,
        filtered: this.filtered,
        filteredData: this.filteredData,
        productData: this.products,
        orderProductsBy: this.sortBy
      })
      return products
    },
    featuredImage() {
      let result = null
      if (
        this.collection &&
        this.collection.featuredMedia &&
        this.collection.featuredMedia.src
      ) {
        result = this.collection.featuredMedia.src
      }
      return result
    },
    isQuiz() {
      return this.collection && this.collection.handle === "guided-quiz";
    },
    collectionOrder() {
      const page = this.page
      return get(page, 'fields.superCollection')
        ? get(page, 'fields.collectionsOrder')
        : null
    }
  },
  mounted() {
    if (this.collection && !process.server && process.env.NOSTO_ENABLED) {
      if (window.nostoClient) {
        window.nostoClient
          .viewCollection({
            collection: this.collection,
            elements: ['categorypage-nosto-2']
          })
          .then(data => (this.recos = data))
      } else {
        console.warn('nostoClient not found')
      }
    }
  },
  head() {
    if (this.page) {
      const properties = {}
      const meta = []
      const title = this.getMetatag('title')

      if (this.page.title) {
        let fullTitle = this.page.title

        if (title) {
          fullTitle = `${fullTitle} | ${title.value}`
        }

        properties.title = fullTitle
        meta.push({
          hid: 'og:title',
          property: 'og:title',
          content: fullTitle
        })
      }

      if (this.page.description) {
        meta.push({
          hid: 'description',
          name: 'description',
          content: this.page.description
        })
        meta.push({
          hid: 'og:description',
          property: 'og:description',
          content: this.page.description
        })
      }

      if (this.featuredImage) {
        meta.push({
          hid: 'og:image',
          property: 'og:image',
          content: this.featuredImage
        })
      }

      return {
        ...properties,
        meta
      }
    }
  },
  watch: {
    filteredData(value) {
      const products = this.products
      const route = this.$route
      if (
        products &&
        products.length &&
        value &&
        value.length &&
        (
          route.query.color ||
          route.query.size ||
          route.query.priceRange ||
          route.query.engraving
        )
      ) {
        console.log('setting filtered', {
          products,
          route,
          filteredData: value
        })
        this.filtered = true
      } else {
        this.filtered = false
      }
    },
  },
  methods: {
    sortProducts(val) {
      this.filtered = true
      this.sortBy = val
      this.sorted = true
    },
    toggleFilterModal() {
      this.filtered = true
      this.modalOpen = !this.modalOpen
    },
    updatePagination(i) {
      console.log('collection-page', i)
      this.fetchMore()
    }
  }
})
</script>

<style lang="scss" scoped>
.p-collection { }
</style>
