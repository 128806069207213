<template>
  <div
    class="c-search-overlay"
    :style="overlayStyle"
  >
    <div
      class="c-search-overlay__header"
      :class="[
        'l-flex',
        'l-flex--justify-end',
        'l-flex--align-center'
      ]"
    >
      <button
        class="c-search-overlay__close-button t-paragraph-large"
        :class="['l-flex','l-flex--align-center']"
        @click.stop="handleClose"
      >
        <span> &#215; </span>
        Close
      </button>
    </div>
    <search-box
      class="c-search-overlay__search-box"
      :class="{
        'c-search-overlay__search-box--results': !!query
      }"
      :enable-autocomplete="true"
      :columns="4"
      position="global"
    />
    <search-screen
      v-if="searchVisible && !query"
      class="c-search-overlay__search-screen"
    />
  </div>
</template>

<script>
import SearchBox from '~/components/nacelle/SearchBox'
import SearchScreen from '~/components/screens/SearchScreen'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    SearchBox,
    SearchScreen,
  },
  data() {
    return { overlayStyle: {} }
  },
  computed: mapState('search', [
    'searchVisible',
    'query'
  ]),
  watch: {
    searchVisible() {
      this.updateOffset()
    },
    $route(to, from) {
      if (to && from) {
        this.hideSearch()
      }
    }
  },
  mounted() {
    this.updateOffset()
  },
  beforeRouteLeave(to, from, next) {
    this.hideSearch()
    setTimeout(() => {
      this.$store.commit('search/setQuery', null)
    }, 200)
    next()
  },
  methods: {
    ...mapMutations('search', ['setQuery','hideSearch']),
    updateOffset() {
      const collection = document.getElementsByClassName(
        'j-global-header-height'
      )
      if (collection && collection[0]) {
        const { height } = collection[0].getBoundingClientRect()
        this.overlayStyle = {
          top: `${height}px`,
          'max-height': `calc(100vh - ${height}px)`
        }
      }
    },
    handleClose() {
      this.hideSearch()
      this.setQuery(null)
    },
  },
}
</script>
<style lang="scss" scoped>
.c-search-overlay {
  width: 100vw;
  min-height: rem(450px);
  z-index: $zindex-global-header + 1;
  position: fixed;
  top: rem(140px);
  left: 0;
  overflow-y: scroll;
  @include themify($themes){
    background-color: themed('background', 'base');
    border-bottom: 1px solid themed('foreground', 'section-border');
  }
  @include respond(tab-port) {
    top: 0 !important;
    height: 100vh;
    max-height: 100vh !important;
  }
  @include respond(phone) {
    top: 0 !important;
    height: 100vh;
    max-height: 100vh !important;
  }
  &__close-button {
    padding-top: rem(5px);
    background: none;
    border: none;
    width: rem(105px);
    height: rem(35px);
    cursor: pointer;
    z-index: $zindex-global-header;
    span {
      font-size: rem(35px);
      font-weight: 100;
      padding-right: rem(5px);
    }
  }
  &__header {
    height: rem(20px);
    margin: rem(10px) 0;
  }
  &__search-box {
    padding-top: rem(20px);
    &--results {
      height: 100%;
    }
  }
  &__search-screen {
    padding-top: rem(30px);
  }
}
</style>
