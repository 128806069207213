<template>
  <div
    class="c-cart-item"
    :class="[
      'l-flex',
      'l-flex--row',
      'l-flex--justify-start'
    ]"
  >
    <router-link
      :to="`${pathFragment}${item.handle}`"
      class="c-cart-item__link l-flex"
      @click.native="hideCart"
    >
      <product-image
        v-if="productThumbnail && productThumbnail.length > 0"
        aria-hidden="true"
        class="c-cart-item__link-image"
        :source="productThumbnail"
        :alt="item.title"
      />
    </router-link>

    <div class="c-cart-item__info">
      <router-link
        class="c-cart-item__title-link"
        :to="`${pathFragment}${item.handle}`"
        @click.native="hideCart"
      >
        <product-title
          class="c-cart-item__title"
          :title="item.title"
        />
      </router-link>
      <div
        class="c-cart-item__details"
        :class="[
          'l-flex',
          'l-flex--column',
          'l-flex--align-start'
        ]"
      >
        <product-variant-title
          :title="variant.title"
          :item="item"
          class="c-cart-item__variant-title"
          :engraving-fee="engravingFee"
          :sleeve-fee="sleeveFee"
        />
        <nacelle-product-price
          v-if="isGift || item.discountApplied"
          :product="item"
          :price="item.variant.price"
        />
        <product-price
          v-else
          :price="item.variant.price | currency"
          :compare-at-price="item.variant.compareAtPrice | currency"
        >
          <product-price-pill
            v-if="item.variant.price < item.variant.compareAtPrice"
            color="cyan"
          >
            {{ discount }}% off
          </product-price-pill>
          <product-price-pill
            v-if="isClearanceItem"
            color="red"
          >
            Final Sale
          </product-price-pill>
        </product-price>
        <quantity-selector
          v-if="canChangeQuantity"
          class="c-cart-item__quantity"
          :item="item"
          :quantity="item.quantity"
        />
        <cart-flyout-item-remove-button
          class="c-cart-item__remove-button"
          :line-item="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CartFlyoutItemRemoveButton from '~/components/cart/CartFlyoutItemRemoveButton'
import get from 'lodash/get'
import NacelleProductPrice from '~/components/nacelle/ProductPrice'
import ProductImage from '~/components/nacelle/ProductImage'
import ProductPrice from '@/components/productPrice/ProductPrice'
import ProductPricePill from '@/components/productPricePill/ProductPricePill'
import ProductTitle from '~/components/nacelle/ProductTitle'
import ProductVariantTitle from '~/components/nacelle/ProductVariantTitle'
import QuantitySelector from '~/components/nacelle/QuantitySelector'
import round from 'lodash/round'
import { mapMutations, mapGetters } from 'vuex'
export default {
  components: {
    ProductImage,
    ProductTitle,
    ProductPrice,
    NacelleProductPrice,
    ProductVariantTitle,
    QuantitySelector,
    CartFlyoutItemRemoveButton,
    ProductPricePill
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    pathFragment: {
      type: String,
      default: '/products/'
    },
    fees: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('cart', ['productHasGiftWithPurchase']),
    isGift() {
      return this.productHasGiftWithPurchase(this.item)
    },
    canChangeQuantity() {
      const item = this.item
      return (
        !item.customAttributes ||
        !item.customAttributes[0] ||
        item.customAttributes[0].key != '_lineGiftWithPurchase'
      )
    },
    productThumbnail() {
      const item = this.item
      return (
        get(item, 'image.thumbnailSrc') ||
        get(item, 'featuredImage.thumbnailSrc') ||
        get(item, 'featuredMedia.thumbnailSrc') ||
        get(item, 'variant.featuredMedia.thumbnailSrc') ||
        ''
      )
    },
    variant() {
      const defaultVariant = {
        id: '',
        title: '',
        price: 0
      }

      if (this.item && this.item.variant) {
        return {
          ...defaultVariant,
          ...this.item.variant
        }
      }

      return defaultVariant
    },
    engravingFee() {
      let result = this.fees.filter((fee) => {
        return fee.handle === 'engraving-fee'
      })
      if (result && result[0]) {
        return result[0].variants[0]
      }
      return null
    },
    sleeveFee() {
      let result = this.fees.filter((fee) => {
        return fee.handle === 'bracelet-sleeve-fee'
      })
      if (result && result[0]) {
        return result[0].variants[0]
      }
      return null
    },
    isClearanceItem() {
      const product = this.item
      const tags = get(product, 'tags') || []
      return tags.includes('clearance')
    },
    discount() {
      const salePrice = this.item.variant.price
      const comparePrice = this.item.variant.compareAtPrice
      const integerPercent = (comparePrice - salePrice)  / comparePrice * 100
      // console.log({ integerPercent, f: Math.floor(integerPercent), r: round(integerPercent) })
      return round(integerPercent)
    }
  },
  methods: {
    ...mapMutations('cart', ['hideCart'])
  }
}
</script>

<style lang="scss" scoped>
.c-cart-item {
  position: relative;
  padding: rem(20px);
  padding-right: rem(40px);
  &__link {
    max-width: rem(100px);
    margin-right: rem(20px);
  }
  &__link-image {
    max-width: rem(100px);
    width: rem(100px);
  }
  &__info {
    flex: 1;
  }
  &__details {
    width: calc(100% - #{rem(48px)});
  }
  &__title-link {
    display: block;
    text-decoration: none;
    width: calc(100% - #{rem(24px)});
    @include themify($themes) {
      color: themed('primary', 'base');
    }
  }
  &__quantity {
    position: absolute;
    right: rem(20px);
    top: 50%;
    transform: translateY(-50%);
  }
  &__remove-button {
    position: absolute;
    right: rem(20px);
    top: rem(20px);
  }
}
</style>
