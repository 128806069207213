<template>
  <div class="c-search-screen l-container">
    <search-panel
      v-if="productsData && productsData.length"
      :popular-searches="popularSearches"
      :trending-products="productsData"
    />
    <div
      v-else
      class="c-search-screen__loader"
    >
      <loader />
    </div>
  </div>
</template>

<script>
import Loader from '@/components/loader/Loader'
import SearchPanel from '~/components/nacelle/SearchPanel'
export default {
  name: 'SearchScreen',
  components: {
    Loader,
    SearchPanel,
  },
  async fetch() {
    try {
      console.log('[search-screen] loading..')
      this.productsData = await this.$api.getProducts({
        handles: this.trendingProductHandles
      })
    } catch (err) {
      console.error(err)
    }
  },
  fetchOnServer: false,
  data() {
    return {
      trendingProductHandles: [
        'etched-classic-silicone-ring-azul-sugar-skull',
        'elements-classic-silicone-ring-black-pearl',
        'elements-classic-thin-silicone-ring-rose-gold',
        'bevel-silicone-ring-obsidian'
      ],
      productsData: [],
      popularSearches: [
        {
          title: 'birthstone',
          linkUrl: '/search?q=birthstone'
        },
        {
          title: 'inked',
          linkUrl: '/search?q=inked'
        },
        {
          title: 'skull',
          linkUrl: '/search?q=skull'
        },
        {
          title: 'mermaid',
          linkUrl: '/search?q=mermaid'
        },
        {
          title: 'rose gold',
          linkUrl: '/search?q=rose gold'
        },
      ],
    }
  }
}
</script>
<style lang="scss" scoped>
.c-search-screen {
}
</style>
