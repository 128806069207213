<!--
/****
/* For information about search & filtering, please refer to:
/*
/* https://docs.getnacelle.com/nuxt/filtering-products.html#filtering-searching
/****
-->
<template>
  <div class="p-search l-flex l-flex--column">
    <div
      class="nosto_page_type"
      style="display: none;"
    >
      search
    </div>
    <div class="p-search__search-box">
      <search-box
        position="in-page"
        :auto-complete-visible="true"
      />
    </div>
    <refinement-results
      v-if="showResults"
      class="p-search__results"
      :search-data="searchData"
      :search-query="query"
    >
      <template v-slot:result="{ result }">
        <product-grid
          :products="result"
          :columns="4"
          :enable-stars="true"
          :max-products="400"
        />
      </template>
      <template v-slot:no-results>
        <search-no-results v-if="searchData" />
        <loader v-else />
      </template>
    </refinement-results>
    <search-screen v-else-if="productData" />
    <loader v-else />
  </div>
</template>

<script>
import Loader from '@/components/loader/Loader'
import ProductGrid from '@/components/productGrid/ProductGrid'
import RefinementResults from '~/components/nacelle/RefinementResults'
import SearchBox from '~/components/nacelle/SearchBox'
import SearchNoResults from '~/components/nacelle/SearchNoResults'
import SearchScreen from '~/components/screens/SearchScreen'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    Loader,
    ProductGrid,
    RefinementResults,
    SearchBox,
    SearchNoResults,
    SearchScreen,
  },
  data() {
    return {
      filteredData: null,
    }
  },
  beforeRouteLeave(to, from, next) {
    this.hideSearch()
    setTimeout(() => {
      this.$store.commit('search/setQuery', null)
    }, 200)
    next()
  },
  computed: {
    ...mapState('search', ['query', 'loadedData']),
    ...mapGetters('search', ['productData']),
    showResults() {
      const searchData = this.searchData
      const query = this.query
      return !!(query && searchData)
    },
    searchData() {
      const filteredData = this.filteredData
      const productData = this.productData
      if (filteredData && filteredData.length) {
        return filteredData
      } else if (productData && productData.length) {
        return productData
      } else {
        return null
      }
    }
  },
  methods: mapMutations('search', ['setQuery', 'hideSearch']),
}
</script>

<style lang="scss" scoped>
.p-search {
  &__search-box,
  &__filter-bar,
  &__results {
    width: 100%;
  }
}
</style>
